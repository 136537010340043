import React, { useContext } from "react";
import { BananaContext } from "@wikimedia/react.i18n";

import Box from "@material-ui/core/Box";

import Logo from "../../ui/Logo";
import LogoIcon from "../../ui/LogoIcon";

import { useStyles } from "./style";

export function PaybyrdLogo({ open = true, ...props }) {
  const translator = useContext(BananaContext);
  const classes = useStyles();

  return (
    <Box
      {...props}
      classes={{
        root: classes.logo,
      }}
      title={translator.i18n("click-to-toggle")}
    >
      {open ? <Logo className={classes.icon} /> : <LogoIcon />}
    </Box>
  );
}

export default PaybyrdLogo;
