import { createSlice } from "@reduxjs/toolkit";
import { createSagaAction } from "saga-toolkit";
import { DEFAULT_PAGE_SIZE } from "services/config";

const name = "webhooks";

export const actions = {
  FETCH: `${name}/fetchWebhooks`,
};

export const fetchWebhooks = createSagaAction(actions.FETCH);

export const webhooksSlice = createSlice({
  name,
  initialState: {
    loading: true,
    error: null,
    success: null,
    webhooks: null,
    pagination: {
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalAvailable: 0,
    },
    filters: {
      referenceId: "",
    },
  },
  reducers: {
    setPagination: (state, { payload }) => ({ ...state, pagination: { ...state.pagination, ...payload } }),
    setFilters: (state, { payload }) => ({ ...state, filters: payload, pagination: { ...state.pagination, page: 1 } }),
  },
  extraReducers: {
    [fetchWebhooks.pending]: (state) => ({ ...state, loading: true }),
    [fetchWebhooks.fulfilled]: (state, { payload: { data, pagination } }) => ({
      ...state,
      loading: false,
      success: true,
      webhooks: data,
      pagination: {
        ...state.pagination,
        ...pagination,
      },
    }),
    [fetchWebhooks.rejected]: (state) => ({ ...state, loading: false, error: true }),
  },
});

export const { setFilters, setPagination } = webhooksSlice.actions;
export default webhooksSlice.reducer;
