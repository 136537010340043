import { useContext } from "react";
import { useDispatch } from "react-redux";
import { BananaContext } from "@wikimedia/react.i18n";

import { openModal } from "store/reducers/ui";

import { MODAL_TYPES } from "./utils";

export function useModals() {
  const translator = useContext(BananaContext);
  const dispatch = useDispatch();

  const openRefund = (props) =>
    dispatch(
      openModal({
        title: translator.i18n("refund-transaction"),
        type: MODAL_TYPES.REFUND,
        props,
      })
    );
  const openCapture = (props) =>
    dispatch(
      openModal({
        title: translator.i18n("capture-transaction"),
        type: MODAL_TYPES.CAPTURE,
        props,
      })
    );
  const openDetail = (props) =>
    dispatch(openModal({ title: " ", type: MODAL_TYPES.DETAIL, props }));
    
  const openWebhookDetail = (props) =>
    dispatch(
      openModal({ title: " ", type: MODAL_TYPES.WEBHOOK_DETAIL, props })
    );
  const openShiftDetail = (props) =>
    dispatch(
      openModal({
        title: translator.i18n("shift-details"),
        type: MODAL_TYPES.SHIFT_DETAIL,
        props,
      })
    );
  const openOnboardingDetail = (props) =>
    dispatch(
      openModal({
        title: translator.i18n("onboarding-details"),
        type: MODAL_TYPES.ONBOARDING_DETAIL,
        props,
      })
    );
  const openCreatedSocialPayment = (props) =>
    dispatch(
      openModal({
        title: translator.i18n("social-payments-title"),
        type: MODAL_TYPES.CREATED_SOCIAL_PAYMENT,
        props,
      })
    );
  const openSocialPaymentDetail = (props) =>
    dispatch(
      openModal({
        title: translator.i18n("details"),
        type: MODAL_TYPES.SOCIAL_PAYMENT_DETAIL,
        props,
      })
    );
  const openChangePasswordStore = (props) =>
    dispatch(
      openModal({
        title: translator.i18n("change-terminal-password"),
        type: MODAL_TYPES.STORE_PASSWORD,
        props,
      })
    );
  const openExportReportDetail = (props) =>
    dispatch(
      openModal({
        title: translator.i18n("export-details"),
        type: MODAL_TYPES.EXPORT_REPORT_DETAIL,
        props,
      })
    );

  return {
    openRefund,
    openCapture,
    openDetail,
    openShiftDetail,
    openOnboardingDetail,
    openCreatedSocialPayment,
    openSocialPaymentDetail,
    openWebhookDetail,
    openExportReportDetail,
    openChangePasswordStore,
  };
}
