import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "common/ui/Modal";
import { closeModal } from "store/reducers/ui";

import RefundModal from "./refund";
import CaptureModal from "./capture";
import DetailModal from "./detail";
import WebhookDetailModal from "./webhookDetail";
import ShiftDetailModal from "./shiftDetail";
import OnboardingDetail from "./onboardingDetail";
import CreatedSocialPayment from "./createdSocialPayment";
import SocialPaymentDetail from "./socialPaymentDetail";
import ExportReportModal from "./export";
import ChangeStorePassword from "./storePassword";

const modalComponentLookupTable = {
  RefundModal,
  CaptureModal,
  DetailModal,
  WebhookDetailModal,
  ShiftDetailModal,
  OnboardingDetail,
  CreatedSocialPayment,
  SocialPaymentDetail,
  ExportReportModal,
  ChangeStorePassword
};

export const ModalManager = () => {
  const dispatch = useDispatch();
  const currentModals = useSelector((state) => state.ui.modals);

  const onClose = (key) => dispatch(closeModal(key));

  const renderModals = () => {
    //console.log(currentModals);
    return currentModals
      .map((modal, index) => {
        if (modal) {
          const { type, props = {}, title, key } = modal;
          const ModalComponent = modalComponentLookupTable[type];

          return (
            <Modal
              title={title}
              open={true}
              onClose={() => onClose(key)}
              close
              key={type + index}
              fullscreen={props.fullscreen}
            >
              <ModalComponent {...props} onClose={() => onClose(key)} />
            </Modal>
          );
        }

        return null;
      })
      .filter((x) => x);
  };

  return <Fragment>{renderModals()}</Fragment>;
};

export default ModalManager;
