import React, { useState, Fragment, useEffect, useContext } from "react";
import { BananaContext } from "@wikimedia/react.i18n";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import PaybyrdLoginLogo from "common/ui/PaybyrdLoginLogo";

import { useAuth } from "common/auth/Auth0ProviderWithHistory";
import { useStyles } from "./style";
import { MERCHANT_ONBOARDING_URL } from "services/config";

/* const LoginButton = (props) => {
	const { loginWithRedirect } = useAuth0();

	return (
		<Button variant="contained" color="primary" onClick={() => loginWithRedirect()} style={props.style}>Login</Button>
	);
}; */

/* export const LogoutButton = () => {
	const { logout } = useAuth0();

	return (
		<Button variant="contained" color="primary" onClick={() => logout({
			returnTo: window.location.origin,
		})}>Logout</Button>
	);
}; */

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().when("isLogin", {
      is: true,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
    isLogin: yup.boolean(),
  })
  .required();

export const Login = () => {
  // We can get a reason from the url
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const translator = useContext(BananaContext);
  const [recoverSuccess, setRecoverSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [showText, setShowText] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const theme = useTheme();
  const classes = useStyles();
  const { login, changePassword, socialLogin } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      isLogin,
    },
  });

  useEffect(() => {
    reset({ email: "", password: "", isLogin });
    setError(null);
    setRecoverSuccess(null);
  }, [isLogin]);

  //return isAuthenticated ? <LogoutButton /> : <LoginButton />;
  const handleLogin = (data) => {
    const { email, password } = data;
    login(
      {
        email,
        password,
      },
      (error, resp) => {
        if (error) {
          setError(error.description);
        } else {
          console.log(resp);
        }
      }
    );
  };

  const handleChangePassword = (data) => {
    //console.log(data);
    changePassword(data.email, (error, resp) => {
      if (error) {
        setError(error.description);
      } else {
        setRecoverSuccess(translator.i18n("recovery-password-success"));
        //console.log(resp);
      }
    });
  };

  const handleKeyUp = (event) => {
    const { code } = event;

    if (code && code === "Enter") {
      if (isValid) {
        handleSubmit(handleLogin)();
      }
    }
  };

  const handleForgotPassword = () => setIsLogin(false);
  const handleBack = () => setIsLogin(true);
  const handleClickShowPassword = () => setShowText(!showText);
  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Box className={classes.container}>
          <PaybyrdLoginLogo />
          <Link
            data-testid="registerbutton"
            href={MERCHANT_ONBOARDING_URL}
            target="_blank"
            className={classes.btnRound}
          >
            {translator.i18n("register-now")}
          </Link>
        </Box>
      </Box>
      <Box id="main">
        <Box className={classes.contentSection}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.textWrap}>
              {params && params.reason ? (
                <Typography
                  style={{ color: theme.palette.black.main, marginBottom: 20, display: "inline-block" }}
                  variant="p"
                >
                  {translator.i18n(params.reason, "")}
                </Typography>
              ) : (
                ""
              )}
              <Typography className={classes.textTitle} variant="h2">
                {isLogin ? translator.i18n("log-into-paybyrd") : translator.i18n("recover-paybyrd-password")}
              </Typography>
            </Box>
            <Box className={classes.formRow} onKeyUp={handleKeyUp}>
              <Box className={classes.formGroup}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      inputProps={{
                        "data-testid": "email",
                      }}
                      placeholder={translator.i18n("email")}
                      fullWidth={true}
                      InputProps={{
                        className: classes.formControl,
                        disableUnderline: true,
                      }}
                      {...field}
                    />
                  )}
                />
              </Box>
              {isLogin && (
                <Fragment>
                  <Box className={classes.formGroup}>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          inputProps={{
                            "data-testid": "password",
                          }}
                          placeholder={translator.i18n("password")}
                          type={showText ? "text" : "password"}
                          fullWidth={true}
                          InputProps={{
                            className: classes.formControl,
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-testid="showhide"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showText ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box className={classes.formGroup}>
                    <Button
                      data-testid="recoverbutton"
                      className={classes.textButton}
                      variant="text"
                      onClick={handleForgotPassword}
                    >
                      {translator.i18n("forgot-password")}
                    </Button>
                  </Box>
                  <Box className={classes.formGroup}>
                    <Button
                      data-testid="loginbutton"
                      disabled={!isValid}
                      className={classes.loginButton}
                      onClick={handleSubmit(handleLogin)}
                    >
                      {translator.i18n("login")}
                    </Button>
                  </Box>
                  {/* <Box className={classes.formGroup}>
										<Button className={classes.loginButton} onClick={() => socialLogin("facebook")}>Facebook</Button>
									</Box> */}
                </Fragment>
              )}
              {!isLogin && (
                <Box className={classes.buttonsContainer}>
                  <Box className={[classes.formGroup, classes.doubleGroup]}>
                    <Button data-testid="backbutton" className={classes.backButton} onClick={handleBack}>
                      {translator.i18n("back")}
                    </Button>
                  </Box>
                  <Box className={[classes.formGroup, classes.doubleGroup]}>
                    <Button
                      data-testid="sendemailbutton"
                      disabled={!isValid}
                      className={classes.loginButton}
                      onClick={handleSubmit(handleChangePassword)}
                    >
                      {translator.i18n("send-recover-email")}
                    </Button>
                  </Box>
                </Box>
              )}
              {error && <Typography className={classes.formError}>{error}</Typography>}
              {recoverSuccess && <Typography className={classes.formSuccess}>{recoverSuccess}</Typography>}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box className={classes.login}>
			<Box className={classes.left}>
				<Box className={classes.loginContainer}>
					<PaybyrdLogo />
					<TextField id="outlined-basic" label="Outlined" variant="outlined" />
					<TextField id="outlined-basic" label="Outlined" variant="outlined" />
					<Button onClick={handleLogin}>Login</Button>
					<Button onClick={handleChangePassword}>Change password</Button>
				</Box>
			</Box>
			<Hidden xsDown>
				<Box className={classes.right}>

				</Box>
			</Hidden>
		</Box> */}
    </Box>
  );
};

export default Login;
