import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  app: {
    transition: theme.transitions.create("padding-left", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  open: {
    paddingLeft: 0,
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.props.menuWidthSmall,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.props.menuWidth,
    },
  },
  close: {
    paddingLeft: 0,
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.props.menuWidthClosed,
    },
  },
}));
