import { createSlice } from "@reduxjs/toolkit";
import { createSagaAction } from "saga-toolkit";

import { DEFAULT_PAGE_SIZE, CHARGEBACK_STATUSES } from "services/config";

import { getPeriod } from "services/utils";

const name = "chargebacks";

export const actions = {
  FETCH: `${name}/fetch`,
  CREATE: `${name}/create`,
  CHARGEBACK: `${name}/chargeback`,
  FILE: `${name}/files`,
  ACCEPT: `${name}/accept`,
  DISPUTE: `${name}/dispute`,
  WON: `${name}/won`,
  LOST: `${name}/lost`,
  REQUEST_INFO: `${name}/requestInfo`,
  SEND_MESSAGE: `${name}/sendMessage`,
  ATTACH_FILE: `${name}/attachFile`,
};

export const fetch = createSagaAction(actions.FETCH);
export const create = createSagaAction(actions.CREATE);
export const chargeback = createSagaAction(actions.CHARGEBACK);
export const file = createSagaAction(actions.FILE);
export const accept = createSagaAction(actions.ACCEPT);
export const dispute = createSagaAction(actions.DISPUTE);
export const won = createSagaAction(actions.WON);
export const lost = createSagaAction(actions.LOST);
export const requestInfo = createSagaAction(actions.REQUEST_INFO);
export const sendMessage = createSagaAction(actions.SEND_MESSAGE);
export const attachFile = createSagaAction(actions.ATTACH_FILE);

export const initialFilters = {
  period: getPeriod(),
  statuses: [
    CHARGEBACK_STATUSES.DISPUTING,
    CHARGEBACK_STATUSES.LOST,
    CHARGEBACK_STATUSES.WON,
    CHARGEBACK_STATUSES.PENDING_ACTION,
    CHARGEBACK_STATUSES.CREATED,
    CHARGEBACK_STATUSES.ACCEPTED,
  ],
  storeId: [],
};

export const chargebacksSlice = createSlice({
  name,
  initialState: {
    loading: true,
    data: [],
    error: null,
    success: null,
    pagination: {
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      returned: 0,
      total: 0,
      totalAvailable: 0,
    },
    filters: initialFilters,
  },
  reducers: {
    setFilters: (state, { payload }) => ({ ...state, filters: payload }),
    clear: (state) => ({ ...state, error: null, success: null }),
    setSuccess: (state, { payload }) => ({ ...state, success: payload }),
  },
  extraReducers: {
    [fetch.pending]: (state) => {
      if (state.loading === false) {
        state.loading = true;
      }
    },
    [fetch.fulfilled]: (state, { payload: { data, pagination } }) => ({
      ...state,
      loading: false,
      data,
      pagination,
    }),
    [fetch.rejected]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [fetch.rejected]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [create.pending]: (state) => ({ ...state, loading: true }),
    [create.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [create.rejected]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [chargeback.pending]: (state) => ({ ...state, loading: true }),
    [chargeback.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [chargeback.rejected]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [file.pending]: (state) => ({ ...state, loading: true }),
    [file.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [file.rejected]: (state) => ({ ...state, loading: false, error: true }),
    [accept.pending]: (state) => ({ ...state, loading: true }),
    [accept.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [accept.rejected]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [dispute.pending]: (state) => ({ ...state, loading: true }),
    [dispute.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [dispute.rejected]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [won.pending]: (state) => ({ ...state, loading: true }),
    [won.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [won.rejected]: (state) => ({ ...state, loading: false, error: true }),
    [lost.pending]: (state) => ({ ...state, loading: true }),
    [lost.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [lost.rejected]: (state) => ({ ...state, loading: false, error: true }),
    [requestInfo.pending]: (state) => ({ ...state, loading: true }),
    [requestInfo.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [requestInfo.rejected]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [sendMessage.pending]: (state) => ({ ...state, loading: true }),
    [sendMessage.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [sendMessage.rejected]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [attachFile.pending]: (state) => ({ ...state, loading: true }),
    [attachFile.fulfilled]: (state) => ({
      ...state,
      loading: false,
      success: true,
    }),
    [attachFile.rejected]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
});

export const { setFilters, clear, setSuccess } = chargebacksSlice.actions;
export default chargebacksSlice.reducer;
