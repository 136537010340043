import React, { useState, Fragment, useContext } from "react";
import { BananaContext } from "@wikimedia/react.i18n";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormPasswordInput from "common/ui/FormPasswordInput";
import { useChangePassword } from "./useChangePassword";
import Typography from "@material-ui/core/Typography";
import { useConfirm } from "material-ui-confirm";

export function StorePassword({ store, onClose }) {
  const translator = useContext(BananaContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { changePassword, loading } = useChangePassword();
  const confirm = useConfirm();

  const showMessage = async message =>{
    await confirm({
      title: message,
      confirmationButtonProps: {
        variant: "contained",
        color: "primary",          
      },
      cancellationButtonProps: {
        disabled: true,
      },
      cancellationText: ""
    });
  }

  const handleInputChange = (event, callback) => {
    const result = event.target.value.replace(/\D/g, '');
    callback(result);
  };

  const handleChangePassword = async () => {      
    if (password.trim().length < 6)
    {
      await showMessage(translator.i18n("password-must-have-six-numbers"));      
      return;
    }
  
    if (password !== confirmPassword)
    {
      await showMessage(translator.i18n("passwords-do-not-match-message"));      
      return;
    }
    
    try {
      await confirm({
        title: translator.i18n("confirm-change-password-message"),
        confirmationButtonProps: {
          variant: "contained",
          color: "primary",          
        },
        cancellationButtonProps: {
          variant: "contained",
          color: "secondary",          
        },
        confirmationText: translator.i18n("yes"),
        cancellationText:translator.i18n("no")
      });
      
      await changePassword(store.value, password);
      onClose();
    } catch (error) {
      console.log(error);
    } 
  };

  return (
    <Fragment>
      <Typography variant="h6" id="simple-modal-title">
          {translator.i18n("store")}: {store.value} - {store.label}
      </Typography> 
      <FormPasswordInput
        max={6}
        autoFocus={true}
        tabIndex={1}
        style={{ marginTop: 32, marginBottom: 80 }}
        label={translator.i18n("new-password")}
        value={password}
        onChange={(event) => handleInputChange(event, setPassword)}       
      />         
      <FormPasswordInput
        max={6}
        tabIndex={2}
        style={{ marginTop: 32, marginBottom: 80 }}
        label={translator.i18n("confirm-new-password")}
        value={confirmPassword}
        onChange={(event) =>  handleInputChange(event, setConfirmPassword)}                
      />
      <Grid container direction="row" spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button disabled={loading} variant="outlined" onClick={onClose}>
            {translator.i18n("cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangePassword}
          >
            {loading ? translator.i18n("changing-password") : translator.i18n("save-password")}
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default StorePassword;
