import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function DinersClubIcon(props) {
	return (
		<SvgIcon {...props} viewBox="350 -30 40 530" style={{ width:40, height:40 }}>
            <g id="diners" sketchType="MSLayerGroup">
                <path id="Shape-path" sketchType="MSShapeGroup" fill="#0079BE" d="M584.934,236.947c0-99.416-82.98-168.133-173.896-168.1   h-78.241c-92.003-0.033-167.73,68.705-167.73,168.1c0,90.931,75.729,165.641,167.73,165.203h78.241   C501.951,402.587,584.934,327.857,584.934,236.947L584.934,236.947z"/>
                <path id="Shape-path_1_" sketchType="MSShapeGroup" fill="#FFFFFF" d="M333.281,82.932   c-84.069,0.026-152.193,68.308-152.215,152.58c0.021,84.258,68.145,152.532,152.215,152.559   c84.088-0.026,152.229-68.301,152.239-152.559C485.508,151.238,417.369,82.958,333.281,82.932L333.281,82.932z"/>
                <path id="Path" sketchType="MSShapeGroup" fill="#0079BE" d="M237.066,235.098c0.08-41.18,25.747-76.296,61.94-90.25v180.479   C262.813,311.381,237.145,276.283,237.066,235.098z M368.066,325.373V144.848c36.208,13.921,61.915,49.057,61.981,90.256   C429.981,276.316,404.274,311.426,368.066,325.373z"/>
            </g>
		</SvgIcon>
	);
}

export default DinersClubIcon;

