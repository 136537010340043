import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import queryString from "query-string";
import "core-js/stable";
import "regenerator-runtime/runtime";

import { Auth0ProviderWithHistory } from "./common/auth/Auth0ProviderWithHistory";
import store from "./store";
import App from "./App";

ReactDOM.render(
	<HashRouter>
		<QueryParamProvider
			adapter={ReactRouter5Adapter}
			options={{
				searchStringToObject: queryString.parse,
				objectToSearchString: queryString.stringify,
			}}
		>
			<Auth0ProviderWithHistory>
				<Provider store={store}>
					<App />
				</Provider>
			</Auth0ProviderWithHistory>
		</QueryParamProvider>
	</HashRouter>,
	document.getElementById("root")
);
