import React, { useEffect, lazy, useState, Fragment, useRef } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { ApmRoute } from "@elastic/apm-rum-react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import useHotjar from "react-use-hotjar";

import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { useAuth } from "common/auth/Auth0ProviderWithHistory";

import ProtectedRoute from "common/auth/ProtectedRoute";

import Loading from "common/layout/Loading";
import Home from "features/home";
import Notifier from "features/common/notifier";
import ModalManager from "features/common/modals";
import PageErrorBoundary from "common/layout/PageErrorBoundary";
import NotFound from "common/layout/NotFound";
import Login from "common/login";

import { Menu } from "common/layout/Menu";
import { API_AUDIENCE, PERMISSIONS } from "services/config";

import { actions } from "store/reducers/user";
import { useStyles } from "./style";

import { MERCHANT_ONBOARDING_URL } from "services/config";

const Transactions = lazy(() => import("features/transactions"));
const Orders = lazy(() => import("features/orders"));
const PayByLink = lazy(() => import("features/paybylink"));
const Dashboard = lazy(() => import("features/dashboard"));
const Administration = lazy(() => import("features/administration"));
const Statistics = lazy(() => import("features/statistics"));
const ApiKeys = lazy(() => import("features/developers/ApiKeys"));
const User = lazy(() => import("@features/settings/User"));
const Webhooks = lazy(() => import("@features/developers/Webhooks"));
const Onboard = lazy(() => import("@features/settings/Onboard"));
const Invite = lazy(() => import("@features/invite"));
const Shifts = lazy(() => import("@features/shifts/components/Shifts"));
const Settlements = lazy(() => import("@features/administration/Settlements"));
const SettlementsDetails = lazy(() =>
  import("@features/administration/SettlementsDetails")
);
const Payouts = lazy(() => import("@features/administration/Payouts"));
const PayoutsDetails = lazy(() =>
  import("@features/administration/Payouts/Details")
);
const SocialPaymentsWrapper = lazy(() =>
  import("@features/socialPayments/components/SocialPaymentsWrapper")
);
const Forbidden = lazy(() => import("@features/forbidden"));
const Chargebacks = lazy(() => import("@features/chargebacks"));
const ChargebacksDetail = lazy(() => import("@features/chargebacks/Detail"));
const ChargebacksAdd = lazy(() => import("@features/chargebacks/Add"));

const Stores = lazy(() => import("@features/settings/Store"));

export const Root = () => {
  //const [isLoading, setLoading] = useState(true);
  const globalBanner = useRef(null);
  const globalBannerOffset = globalBanner.current?.clientHeight;
  const dispatch = useDispatch();
  const { user, accessToken, logout, isLoading, needsLogin, refreshToken } =
    useAuth();
  const menuOpen = useSelector((state) => state.ui.menuOpen);
  const phase = useSelector((state) => state.user.phase);
  //const accessToken = useSelector(state => state.user.accessToken);
  const onboardingId = useSelector((state) => state.user.onboardingId);
  const hasToLogout = useSelector((state) => state.user.hasToLogout);
  const onboardingUrl = useSelector((state) => state.user.onboardingUrl);
  const classes = useStyles();
  const dev = process.env.NODE_ENV === "development";
  // TODO - Is it possible to receive this the same as API_URL?
  const stage = window.location.href.includes("dashboard-s");
  const [gqlClient, setGQLClient] = useState(null);
  const { identifyHotjar } = useHotjar();

  useEffect(() => {
    if (user) {
      identifyHotjar(user.sub, { ...user, email: user.name });
    }
  }, [user]);

  /* const handleStyleTypeChange = event => {
    const checked = event.target.checked;
    dispatch(switchMode(checked ? 'light' : 'dark'));
  }; */

  useEffect(() => {
    if (accessToken) {
      const setupGraphQL = (accessToken) => {
        const httpLink = createHttpLink({
          uri: `https://webapp-paybyrd-bff${
            dev || stage ? "-s" : ""
          }.azurewebsites.net/graphql`,
        });

        const authLink = setContext((_, { headers }) => {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${accessToken}`,
            },
          };
        });

        setGQLClient(
          new ApolloClient({
            link: from([authLink, httpLink]),
            cache: new InMemoryCache(),
            defaultOptions: {
              watchQuery: {
                fetchPolicy: "no-cache",
                errorPolicy: "ignore",
              },
              query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
              },
            },
          })
        );
      };

      const getAccessToken = () => {
        // Setup GraphQL after accessToken is received
        setupGraphQL(accessToken);

        dispatch({
          type: actions.GET_USER_INFO,
          payload: {
            accessToken,
          },
        });

        //setLoading(false);
      };

      getAccessToken();
    }
  }, [accessToken]);

  useEffect(() => {
    if (hasToLogout !== null && hasToLogout && user) {
      logout({
        returnTo: window.location.origin,
      });
    }
  }, [hasToLogout]);

  const renderPhase = () => {
    let message = "";

    if (phase === "SendMerchantDocuments") {
      message = (
        <Fragment>
          <Typography>
            This is a test account - You have to provide paybyrd with your
            company's documents.
          </Typography>
          <Link
            style={{ color: "#ffffff" }}
            href={
              dev
                ? `${MERCHANT_ONBOARDING_URL}?id=${onboardingId}&token=${accessToken}`
                : `${onboardingUrl}`
            }
          >
            You can provide them here.
          </Link>
        </Fragment>
      );
    } else if (phase === "WaitMerchantDocumentsReview") {
      message = (
        <Fragment>
          <Typography>
            This is a test account - Your account is still being reviewed.
          </Typography>
        </Fragment>
      );
    }

    return (
      phase && (
        <AppBar
          ref={globalBanner}
          position="fixed"
          style={{ zIndex: "9999999" }}
        >
          {message}
        </AppBar>
      )
    );
  };

  if (!isLoading && needsLogin) {
    return <Login />;
  }

  if (isLoading || gqlClient === null) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={gqlClient}>
      {/*renderPhase()*/}
      {user && <Menu open={menuOpen} offset={globalBannerOffset} />}
      <Box
        style={{ paddingTop: globalBannerOffset }}
        className={clsx(classes.app, {
          [classes.open]: menuOpen && user,
          [classes.close]: !menuOpen && user,
        })}
      >
        <PageErrorBoundary>
          {/* {<Toggle onChange={handleStyleTypeChange} />} */}
          <Switch>
            <ApmRoute exact path="/" component={Home} />
            <ProtectedRoute
              path="/dashboard"
              permissions={[
                PERMISSIONS.REPORTS_TRANSACTIONS,
                PERMISSIONS.REPORTS_STATISTICS,
              ]}
              component={Dashboard}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              path="/transactions"
              component={Transactions}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              path="/orders"
              component={Orders}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_STATISTICS}
              path="/statistics"
              component={Statistics}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.PAYBYLINK}
              path="/paybylink"
              component={PayByLink}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION}
              path="/administration"
              component={Administration}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.DEVELOPERS}
              path="/apikeys"
              component={ApiKeys}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.SHIFTS}
              path="/shifts"
              component={Shifts}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION_INVOICES}
              path="/payouts"
              exact
              component={Settlements}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION_INVOICES}
              path="/payouts/:id"
              exact
              component={SettlementsDetails}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION_INVOICES}
              path="/settlements"
              exact
              component={Payouts}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION_INVOICES}
              path="/settlements/details"
              exact
              component={PayoutsDetails}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              path="/social"
              exact
              component={SocialPaymentsWrapper}
            />
            <ProtectedRoute
              path="/chargebacks/add"
              exact
              component={ChargebacksAdd}
            />
            <ProtectedRoute
              path="/chargebacks/add/:transactionId/:storeId/:storeName/:brandCode"
              exact
              component={ChargebacksAdd}
            />
            <ProtectedRoute
              path="/chargebacks/:id"
              exact
              component={ChargebacksDetail}
            />
            <ProtectedRoute
              permissions={PERMISSIONS.REPORTS_TRANSACTIONS}
              path="/social/:id"
              exact
              component={SocialPaymentsWrapper}
            />
            <ProtectedRoute path="/chargebacks" component={Chargebacks} />
            <ProtectedRoute path="/user" component={User} />
            <ProtectedRoute
              permissions={PERMISSIONS.DEVELOPERS}
              path="/webhooks"
              component={Webhooks}
            />
            <ProtectedRoute path="/onboarding" component={Onboard} />
            <ProtectedRoute path="/invite" component={Invite} />
            <ProtectedRoute
              permissions={PERMISSIONS.ADMINISTRATION}
              path="/stores"
              component={Stores}
            />
            <ProtectedRoute path="/forbidden" component={Forbidden} />
            <Redirect to="/dashboard" />
            <ApmRoute path="*" component={NotFound} />
          </Switch>
          <Notifier />
          <ModalManager />
        </PageErrorBoundary>
      </Box>
    </ApolloProvider>
  );
};

export default Root;
