import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function VPayIcon(props) {
	return (
		<SvgIcon {...props} viewBox="160 -80 40 590" style={{ width:40, height:40 }}>
            <defs>
                <clipPath id="clip1">
                    <path d="M 0 0 L 365 0 L 365 399.058594 L 0 399.058594 Z M 0 0 "/>
                </clipPath>
            </defs>
            <g id="surface1">
                <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(100%,100%,100%)',fillOpacity:1 }} d="M 24.929688 0.0820313 C 11.28125 0.0820313 0 11.140625 0 24.769531 L 0 374.273438 C 0 387.914063 11.28125 398.960938 24.929688 398.960938 L 340.21875 398.960938 C 353.886719 398.960938 364.902344 387.914063 364.902344 374.273438 L 364.902344 24.769531 C 364.902344 11.140625 353.886719 0.0820313 340.21875 0.0820313 L 24.929688 0.0820313 "/>
                <g clipPath="url(#clip1)" clip-rule="nonzero">
                    <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(15.299988%,22.399902%,57.299805%)',fillOpacity:1 }} d="M 24.832031 -0.00390625 C 11.183594 -0.00390625 0.0664063 11.09375 0.0664063 24.722656 L 0.0664063 374.335938 C 0.0664063 387.972656 11.183594 399.058594 24.832031 399.058594 L 340.226563 399.058594 C 353.894531 399.058594 365 387.972656 365 374.335938 L 363.386719 374.335938 C 363.386719 387.082031 353.003906 397.464844 340.226563 397.464844 L 24.832031 397.464844 C 12.082031 397.464844 1.6875 387.082031 1.6875 374.335938 L 1.6875 24.722656 C 1.6875 11.984375 12.082031 1.589844 24.832031 1.589844 L 340.226563 1.589844 C 353.003906 1.589844 363.386719 11.984375 363.386719 24.722656 L 363.386719 374.335938 L 365 374.335938 L 365 24.722656 C 365 11.09375 353.894531 -0.00390625 340.226563 -0.00390625 L 24.832031 -0.00390625 "/>
                </g>
                <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(15.299988%,22.399902%,57.299805%)',fillOpacity:1 }} d="M 364.183594 254.667969 L 364.183594 374.332031 C 364.183594 387.550781 353.46875 398.253906 340.230469 398.253906 L 24.835938 398.253906 C 11.597656 398.253906 0.902344 387.550781 0.902344 374.332031 L 0.902344 254.667969 L 364.183594 254.667969 "/>
                <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(100%,100%,100%)',fillOpacity:1 }} d="M 110.105469 332.023438 L 100.878906 332.023438 L 100.878906 367.453125 L 88.96875 367.453125 L 88.96875 280.121094 L 112.148438 280.121094 C 130.628906 280.121094 141.773438 289.105469 141.773438 305.292969 C 141.773438 323.15625 129.113281 332.023438 110.105469 332.023438 M 111.503906 290.640625 L 100.878906 290.640625 L 100.878906 321.503906 L 110.730469 321.503906 C 122.660156 321.503906 129.738281 316.566406 129.738281 305.695313 C 129.738281 295.695313 123.421875 290.640625 111.503906 290.640625 M 208.226563 368.347656 L 197.96875 341.265625 L 161.914063 341.265625 L 152.03125 367.457031 L 139.984375 367.457031 L 174.171875 279.734375 L 186.082031 279.734375 L 219.75 365.816406 Z M 179.882813 293.910156 L 165.941406 330.757813 L 193.933594 330.757813 Z M 255.582031 332.777344 L 255.582031 367.453125 L 243.6875 367.453125 L 243.6875 332.777344 L 212.792969 281.753906 L 224.699219 279.222656 L 249.746094 321.894531 L 274.179688 280.121094 L 287.347656 280.121094 L 255.582031 332.777344 "/>
                <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(15.299988%,22.399902%,57.299805%)',fillOpacity:1 }} d="M 229.054688 34 L 181.796875 161.464844 L 177.222656 135.585938 C 165.46875 103.859375 142.644531 72.253906 112.921875 58.761719 L 153.085938 220.632813 L 204.203125 220.59375 L 280.207031 34 L 229.054688 34 "/>
                <path style={{ stroke:'none',fillRule:'nonzero',fill:'rgb(97.599792%,62.69989%,10.598755%)',fillOpacity:1 }} d="M 140.105469 33.941406 L 64.457031 33.941406 L 63.605469 38.859375 C 124.1875 54.335938 160.605469 90.675781 177.246094 135.597656 L 161.984375 49.710938 C 159.082031 37.882813 150.625 34.34375 140.105469 33.941406 "/>
            </g>
		</SvgIcon>
	);
}

export default VPayIcon;
