import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function PaymentsIcon(props) {
	return (
		<SvgIcon {...props}>
			<g>
				<rect width="24" height="24" fill="none"/>
				<path d="M23.03223,13a.85492.85492,0,0,0-.033-.16315l-.01782-3.92621a.901.901,0,0,0-.9004-.896h-1.0523l-2.45844-5.459A.89974.89974,0,0,0,17.43262,2.083L1.55078,8.07227l-.01324.0083a.89338.89338,0,0,0-.158.09918.90917.90917,0,0,0-.13928.09344l-.00983.0066A.88032.88032,0,0,0,1.153,8.3963a.89927.89927,0,0,0-.10821.1626.88776.88776,0,0,0-.0379.15985.86941.86941,0,0,0-.035.179c-.00012.00714-.004.01355-.004.02069l.05078,11.19189a.90109.90109,0,0,0,.9004.89649H22.13184a.90192.90192,0,0,0,.90039-.9043l-.0315-6.94653A.85194.85194,0,0,0,23.03223,13ZM2.77246,9.81445H21.18555l.01019,2.28565H2.78265Zm16.28265-1.7998H6.80536l10.471-3.94873ZM2.81445,19.20605l-.02374-5.30566H21.2038l.02374,5.30566Zm16.74274-2.14886A1.05719,1.05719,0,1,1,18.5,16,1.05715,1.05715,0,0,1,19.55719,17.05719Zm-3.55719,0A1.05719,1.05719,0,1,1,14.94281,16,1.05715,1.05715,0,0,1,16,17.05719Z" fill="currentColor"/>
			</g>
		</SvgIcon>
	);
}

export default PaymentsIcon;
