import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: 10,
  },
  alert: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      margin: "0 10px 0 0",
    },
  },
  subtitle: {
    padding: '30px 30px 20px 30px',
    fontSize: 16,
    lineHeight: "30px",
    color: theme.palette.lightGray.main,
    textAlign: "justify",
  },
  formControl: {
    display: "flex",
    marginTop: 20,
    marginBottom: 40,
  },
  options: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-around",
  },
}));
