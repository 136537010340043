import React, { useContext, useState } from 'react';
import { BananaContext } from '@wikimedia/react.i18n';
import Box from '@material-ui/core/Box';
import QRCode from "react-qr-code";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

import CopyIcon from 'common/ui/icons/CopyIcon';

import { useStyles } from './style';

export function CreatedSocialPayment({ link }) {
	const translator = useContext(BananaContext);
	const classes = useStyles();
	const [copied, setCopied] = useState(null);

	return (
		<>
			<Box component="dl" className={classes.definitions} display="flex" flexDirection="row">
				<Typography>{link}</Typography>
				<CopyToClipboard text={link} onCopy={() => setCopied(link)} style={{ marginLeft: '10px' }}>
					<CopyIcon style={{ cursor: "pointer" }}/>
				</CopyToClipboard>
			</Box>
			<Box component="dl" className={classes.definitions} style={{ textAlign: 'center' }}>
				<Box className={classes.definition}>
					<QRCode value={link} />
				</Box>
			</Box>
			<Snackbar open={!!copied} autoHideDuration={6000} onClose={() => setCopied(null)}>
				<Alert severity="info" color="info">
					{translator.i18n('copied-link', copied)}
				</Alert>
			</Snackbar>
		</>
	);
}

export default CreatedSocialPayment;