import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function OrdersIcon(props) {
	return (
		<SvgIcon {...props}>
			<g>
				<rect x="0.00309" width="24" height="24" fill="none"/>
				<path d="M22.80371,5.82568a.89962.89962,0,0,0-.7168-.35547H8.69141L7.58984,2.38428a.90017.90017,0,0,0-.84765-.59717H1.919a.90024.90024,0,0,0-.9004.8999V5.66846a.90024.90024,0,0,0,.9004.8999H3.14746l2.5459,10.10547a.9012.9012,0,0,0,.87305.68066H7.249a2.79492,2.79492,0,0,0-.39837,1.42285,2.82861,2.82861,0,0,0,5.65722,0,2.7959,2.7959,0,0,0-.39813-1.42285h2.32459a2.79581,2.79581,0,0,0-.39814,1.42285,2.82813,2.82813,0,1,0,5.65625,0,2.79633,2.79633,0,0,0-.39923-1.4248.899.899,0,0,0,.85529-.65723l2.80566-10.084A.90144.90144,0,0,0,22.80371,5.82568Zm-13.124,13.979A1.02735,1.02735,0,1,1,10.707,18.77734,1.02881,1.02881,0,0,1,9.67969,19.80469Zm7.18457,0a1.02735,1.02735,0,1,1,1.02734-1.02735A1.02861,1.02861,0,0,1,16.86426,19.80469Zm1.7334-4.251H7.26758l-2.5459-10.105a.90132.90132,0,0,0-.87305-.68018H2.81934V3.58691H6.1084L7.21,6.67285A.90017.90017,0,0,0,8.05762,7.27H20.90234Z" fill="#83858f"/>
			</g>
		</SvgIcon>
	);
}

export default OrdersIcon;