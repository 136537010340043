import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = type => makeStyles((theme) => {
	switch(type) {
		case 'success':
			return {
				tag: {
					color: theme.palette.black.main,
					backgroundColor: theme.palette.green.main,
				}
			};
		case 'error':
			return {
				tag: {
					color: theme.palette.white.main,
					backgroundColor: theme.palette.red.main,
				}
			};
		case 'pending':
			return {
				tag: {
					color: theme.palette.black.main,
					backgroundColor: theme.palette.yellow.main,
				}
			};
		case 'processing':
			return {
				tag: {
					color: theme.palette.black.main,
					backgroundColor: theme.palette.orange.main,
				}
			};
		case 'expired':
		case 'created':
			return {
				tag: {
					color: theme.palette.white.main,
					backgroundColor: theme.palette.blue.main,
				}
			};
		default:
			return {
				tag: {

				}
			}
	}
});

export function Tag({ type, ...props }) {
	const classes = useStyles(type)();
	return <Chip {...props} className={classnames(props.className, classes.tag)} />
}

export default Tag;