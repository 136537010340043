import { createTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { enUS, ptPT, esES, deDE, frFR, nlNL } from '@material-ui/core/locale';

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { hexAToRGBA } from "./utils";


export const colors = {
	black: "#333333",
	white: "#FFFFFF",
	main: "#572D9C",
	purple: "#9C6CFE",
	blue: "#352CAB",
	yellow: "#f1be43",
	red: "#ef5350",
	orange: "#ff9140",
	green: "#36d174",
	"light-gray-2": "#FBFCFE",
	"light-gray": "#bcbdc2",
	"medium-gray": "#83858F",
	"medium-gray-2": "#48494f",
	"dark-gray-2": "#363243",
	"dark-gray": "#202020",
	"baby-green": "#75FEA6",
};

const breakpoints = createBreakpoints({});

const getLocale = locale => {
	switch (locale) {
		case 'en': return enUS;
		case 'pt': return ptPT;
		case 'es': return esES;
		case 'de': return deDE;
		case 'fr': return frFR;
		case 'nl': return nlNL;
		default: return enUS;
	}
}

export const makeTheme = (type, locale) => {
	return createTheme({
		type,
		props: {
			MuiButtonBase: {
				disableRipple: true,
			},
			menuWidth: 260,
			menuWidthClosed: 74,
			menuWidthSmall: 290,
		},
		overrides: {
			MuiCssBaseline: {
				"@global": {
					html: {
						WebkitFontSmoothing: "auto",
					},
					"*::-webkit-scrollbar": {
						width: "10px",
						height: "10px",
					},
					"*::-webkit-scrollbar-thumb": {
						background: "#4a4a4a",
						borderRadius: "30px",
					},
					"*::-webkit-scrollbar-thumb:hover": {
						background: "#565656",
					},
					"*::-webkit-scrollbar-track": {
						background: "#1c1c1c",
						borderRadius: "0px",
					},
				},
			},
			MuiSelect: {
				root: {
					height: 20,
					color:
						type === "dark" ? colors["white"] : colors["dark-gray"],
					fontSize: 14,
					"&:hover, &:hover ~ .MuiOutlinedInput-notchedOutline": {
						borderColor: colors["purple"],
						borderWidth: 1,
					},
					"&.MuiInput-input": {
						fontSize: 16,
						"&.Mui-disabled": {
							color: "currentColor",
						},
					},
				},
				iconOutlined: {
					right: 12,
				},
				select: {
					"&:focus": {
						backgroundColor: "transparent",
					},
				},
			},
			MuiInputBase: {
				root: {
					[breakpoints.up("md")]: {
						width: "auto",
					},
				},
			},
			MuiOutlinedInput: {
				root: {
					transition: "all .3s ease-in-out",
					color: colors["medium-gray"],
					borderRadius: 20,
					"&:hover": {
						backgroundColor: hexAToRGBA(`${colors["main"]}33`),
					},
				},
				input: {
					padding: "8px 14px",
				},
			},
			MuiLinearProgress: {
				colorPrimary: {
					backgroundColor: "rgba(51, 51, 51, .15)",
				},
				barColorPrimary: {
					backgroundColor: colors["white"],
				},
			},
			MuiPaper: {
				root: {
					padding: 24,
				},
				rounded: {
					borderRadius: 12,
				},
			},
			MuiTabs: {
				root: {
					borderBottom: `1px solid ${colors["medium-gray-2"]}`,
				},
			},
			MuiTab: {
				root: {
					minWidth: "0",
					padding: "6px 0",
					marginRight: 52,
					[breakpoints.up("sm")]: {
						minWidth: "0",
					},
					textTransform: "none",
				},
			},
			PrivateTabIndicator: {
				colorSecondary: {
					backgroundColor: colors["main"],
				},
			},
			MuiTableCell: {
				head: {
					borderBottom: "none",
					textTransform: "uppercase",
					color: colors["medium-gray"],
					fontSize: 12,
					fontWeight: 700,
					paddingLeft: 0,
				},
				body: {
					paddingLeft: 0,
				},
				alignRight: {
					padding: 16,
				},
			},
			MuiTable: {
				root: {
					minWidth: 650,
				},
			},
			MuiTableRow: {
				root: {
					"&:last-of-type": {
						"& .MuiTableCell-body": {
							borderBottom: "none",
						},
					},
				},
				hover: {
					"&:hover": {
						backgroundColor: "rgba(255, 255, 255, 0.03) !important",
					},
				},
			},
			MuiChip: {
				label: {
					textTransform: "uppercase",
				},
			},
			MuiButton: {
				root: {
					textTransform: "none",
					borderRadius: 30,
					padding: "6px 15px",
					"&:hover, &:hover ~ .MuiOutlinedInput-notchedOutline": {
						borderColor: colors["purple"],
						borderWidth: 1,
						backgroundColor: hexAToRGBA(`${colors["main"]}33`),
					},
				},
				endIcon: {
					marginRight: 3,
				},
				label: {
					whiteSpace: "nowrap",
				},
			},
			MuiCardHeader: {
				root: {
					padding: 0,
				},
			},
			MuiCardContent: {
				root: {
					padding: 0,
				},
			},
			MuiIconButton: {
				root: {
					"&:hover": {
						backgroundColor: "transparent",
					},
				},
			},
			MuiMenu: {
				paper: {
					backgroundColor: colors["white"],
					color: colors["black"],
					borderRadius: 4,
				},
			},
			MuiListItemIcon: {
				root: {
					color: colors["black"],
					minWidth: 40,
				},
			},
			MuiDialogActions: {
				root: {
					"& .MuiButton-textPrimary": {
						color: colors["white"],
					},
				},
			},
			MuiTablePagination: {
				toolbar: {
					display: "inline-flex",
					flexDirection: "row",
					flexWrap: "wrap",
					[breakpoints.down("md")]: {
						justifyContent: "center",
						padding: 0,
						marginBottom: 16,
					},
				},
				actions: {
					[breakpoints.down("md")]: {
						marginLeft: 0,
					},
				},
			},
			MuiChip: {
				root: {
					height: 36.5,
					borderRadius: 30,
				},
			},
			MuiPickersBasePicker: {
				pickerView: {
					minWidth: 188,
				},
			},
			MuiCheckbox: {
				root: {
					"&:hover": {
						backgroundColor: "transparent !important",
					},
				},
			},
		},
		palette: {
			type,
			secondary: {
				main: colors["white"],
			},
			primary: {
				main: colors["main"],
			},
			black: {
				main: colors["black"],
			},
			background: {
				header: type === "dark"
					? "#222222"
					: colors["light-gray-2"],
				default: type === "dark"
					? "#181818"
					: "#E5E5E5",
				paper: type === "dark"
					? colors["dark-gray"]
					: colors["white"],
				inverse: type === "dark"
					? colors["white"]
					: colors["dark-gray"],
			},
			mediumGray: {
				main: colors["medium-gray"],
			},
			mediumGray2: {
				main: colors["medium-gray-2"],
			},
			darkGray2: {
				main: colors["dark-gray-2"],
			},
			purple: {
				main: colors["purple"],
			},
			white: {
				main: colors["white"],
			},
			lightBlue: {
				main: "#5cbdff",
			},
			blue: {
				main: colors["blue"],
			},
			darkGray: {
				main: colors["dark-gray"],
			},
			babyGreen: {
				main: colors["baby-green"],
			},
			yellow: {
				main: colors["yellow"],
			},
			red: {
				main: colors["red"],
			},
			orange: {
				main: colors["orange"],
			},
			green: {
				main: colors["green"],
			},
			lightGray2: {
				main: colors["light-gray-2"],
			},
			lightGray: {
				main: colors["light-gray"],
			},
			action: {
				selected: colors["light-gray"],
				hover: colors["light-gray"],
				disabled: "#9B9B9B",
			},
		},
		typography: {
			fontFamily: [
				'"Montserrat"',
				"Arial",
				'"Helvetica Neue"',
				"Helvetica",
				"sans-serif",
			].join(","),
			body1: {
				fontSize: 16,
			},
			body2: {
				fontSize: 14,
			},
			h1: {
				fontSize: 24,
				fontWeight: 600,
			},
		},
	},
	getLocale(locale));
};

export default makeTheme;
