import { call, fork } from "redux-saga/effects";
import { takeLatestAsync } from "saga-toolkit";
import { formatISO, parseISO } from "date-fns";
// import { getParamsFromFilters } from "services/utils";
import { DEFAULT_PAGE_SIZE } from "services/config";
import { api } from "api";
import {
	fetch,
	create,
	chargeback,
	file,
	accept,
	dispute,
	won,
	lost,
	requestInfo,
	sendMessage,
	attachFile,
} from "./reducer";

export const getParams = ({
	period: { startDate, endDate },
	storeId,
	pageSize = DEFAULT_PAGE_SIZE,
	pageNumber = 1,
	orderRef,
	transactionId,
	chargebackRef,
	orderId,
	groupId,
	merchantId,
	statuses,
	...filters
}) => ({
	StoreId: storeId,
	MerchantId: merchantId,
	GroupId: groupId,
	PageSize: pageSize,
	Page: pageNumber,
	CreatedAtFrom: startDate,
	CreatedAtTo: endDate,
	OrderRef: orderRef,
	OrderId: orderId,
	ChargebackRef: chargebackRef,
	PaymentId: transactionId,
	Status: statuses,
});

function* handleFetch({ meta: { arg } }) {
	try {
		// console.log(getParams(arg), arg);
		const data = yield call(api.getChargebacks, getParams(arg));
		return data.value ? data.value : data;
	} catch (error) {
		throw new Error(error.message);
	}
}

function* handleCreate({ meta: { arg } }) {
	try {
		const result = yield api.createChargeback(arg);

		if (result.status === 201) {
			return result.data;
		}

		const { publicMessage } = result.data.error;
		const message = JSON.parse(publicMessage);
		const { error } = message;

		throw new Error(error.messages[0]);
	} catch (error) {
		throw error;
	}
}

function* handleChargeback({ meta: { arg } }) {
	try {
		const result = yield api.chargeback(arg);

		if (result.status === 200) {
			return result.data;
		}

		throw new Error("Error getting the Chargeback");
	} catch (error) {
		throw error;
	}
}

function* handleUploadFile({ meta: { arg } }) {
	try {
		const result = yield api.uploadFile(arg, "chargebacks");

		if (result.status === 201) {
			return result.data;
		}

		throw new Error("Error uploading file");
	} catch (error) {
		throw error;
	}
}

function* handleAccept({ meta: { arg } }) {
	try {
		const result = yield api.chargebackAccept(arg);

		if (result.status === 201) {
			return result.data;
		}

		throw new Error("Error accepting the chargeback");
	} catch (error) {
		throw error;
	}
}

function* handleDispute({ meta: { arg } }) {
	try {
		const result = yield api.chargebackDispute(arg);

		if (result.status === 201) {
			return result.data;
		}

		throw new Error("Error changing the chargeback status to disputing");
	} catch (error) {
		throw error;
	}
}

function* handleWon({ meta: { arg } }) {
	try {
		const result = yield api.chargebackWin(arg);

		if (result.status === 201) {
			return result.data;
		}

		throw new Error("Error changing the chargeback status to won");
	} catch (error) {
		throw error;
	}
}

function* handleLost({ meta: { arg } }) {
	try {
		const { id, remark } = arg;
		const result = yield api.chargebackLose(id, remark);

		if (result.status === 201) {
			return result.data;
		}

		throw new Error("Error changing the chargeback status to lost");
	} catch (error) {
		throw error;
	}
}

function* handleRequestInfo({ meta: { arg } }) {
	try {
		const result = yield api.chargebackRequestInformation(arg);

		if (result.status === 201) {
			return result.data;
		}

		throw new Error("Error sending reques for information");
	} catch (error) {
		throw error;
	}
}

function* handleSendMessage({ meta: { arg } }) {
	try {
		const result = yield api.chargebackSendMessage(arg);

		if (result.status === 201) {
			return result.data;
		}

		throw new Error("Error sending message");
	} catch (error) {
		throw error;
	}
}

function* handleAttachFile({ meta: { arg } }) {
	try {
		const result = yield api.chargebackAttachFile(arg);

		if (result.status === 201) {
			return result.data;
		}

		throw new Error("Error sending attach");
	} catch (error) {
		throw error;
	}
}

function* fetchSaga() {
	yield takeLatestAsync(fetch.type, handleFetch);
}

function* createSaga() {
	yield takeLatestAsync(create.type, handleCreate);
}

function* getChargebackSaga() {
	yield takeLatestAsync(chargeback.type, handleChargeback);
}

function* uploadFileSaga() {
	yield takeLatestAsync(file.type, handleUploadFile);
}

function* acceptSaga() {
	yield takeLatestAsync(accept.type, handleAccept);
}

function* disputeSaga() {
	yield takeLatestAsync(dispute.type, handleDispute);
}

function* wonSaga() {
	yield takeLatestAsync(won.type, handleWon);
}

function* lostSaga() {
	yield takeLatestAsync(lost.type, handleLost);
}

function* requestInfoSaga() {
	yield takeLatestAsync(requestInfo.type, handleRequestInfo);
}

function* sendMessageSaga() {
	yield takeLatestAsync(sendMessage.type, handleSendMessage);
}

function* attachFileSaga() {
	yield takeLatestAsync(attachFile.type, handleAttachFile);
}

export function* chargebackSaga() {
	yield fork(fetchSaga);
	yield fork(createSaga);
	yield fork(getChargebackSaga);
	yield fork(uploadFileSaga);
	yield fork(acceptSaga);
	yield fork(disputeSaga);
	yield fork(wonSaga);
	yield fork(lostSaga);
	yield fork(requestInfoSaga);
	yield fork(sendMessageSaga);
	yield fork(attachFileSaga);
}
