import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function DashboardIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M9.303 10.517h-8.090c-0.674 0-1.213-0.539-1.213-1.213v-8.090c0-0.674 0.539-1.213 1.213-1.213h8.090c0.674 0 1.213 0.539 1.213 1.213v8.090c0 0.674-0.539 1.213-1.213 1.213zM2.427 8.090h5.663v-5.663h-5.663v5.663zM22.787 10.517h-8.090c-0.674 0-1.213-0.539-1.213-1.213v-8.090c0-0.674 0.539-1.213 1.213-1.213h8.090c0.674 0 1.213 0.539 1.213 1.213v8.090c0 0.674-0.539 1.213-1.213 1.213zM15.91 8.090h5.663v-5.663h-5.663v5.663zM22.787 24h-8.090c-0.674 0-1.213-0.539-1.213-1.213v-8.090c0-0.674 0.539-1.213 1.213-1.213h8.090c0.674 0 1.213 0.539 1.213 1.213v8.090c0 0.674-0.539 1.213-1.213 1.213zM15.91 21.573h5.663v-5.663h-5.663v5.663zM9.303 24h-8.090c-0.674 0-1.213-0.539-1.213-1.213v-8.090c0-0.674 0.539-1.213 1.213-1.213h8.090c0.674 0 1.213 0.539 1.213 1.213v8.090c0 0.674-0.539 1.213-1.213 1.213zM2.427 21.573h5.663v-5.663h-5.663v5.663z"></path>
		</SvgIcon>
	);
}

export default DashboardIcon;