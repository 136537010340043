import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function TransactionsIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 29 29">
			<path d="M22.787 13.213c-0.27 0-0.674-0.135-0.809-0.404-0.539-0.539-0.539-1.213 0-1.753l4.584-4.584-4.584-4.584c-0.539-0.539-0.539-1.213 0-1.753s1.213-0.539 1.753 0l5.393 5.393c0.539 0.539 0.539 1.213 0 1.753l-5.393 5.393c-0.27 0.404-0.674 0.539-0.944 0.539zM28.18 7.82h-20.225c-0.674 0-1.213-0.539-1.213-1.213s0.539-1.213 1.213-1.213h20.225c0.674 0 1.213 0.539 1.213 1.213s-0.539 1.213-1.213 1.213zM6.607 24c-0.27 0-0.674-0.135-0.809-0.404l-5.393-5.393c-0.539-0.539-0.539-1.213 0-1.753l5.393-5.393c0.539-0.539 1.213-0.539 1.753 0s0.539 1.213 0 1.753l-4.584 4.584 4.584 4.584c0.539 0.539 0.539 1.213 0 1.753-0.27 0.135-0.674 0.27-0.944 0.27zM21.438 18.607h-20.225c-0.674 0-1.213-0.539-1.213-1.213s0.539-1.213 1.213-1.213h20.225c0.674 0 1.213 0.539 1.213 1.213s-0.539 1.213-1.213 1.213z"></path>
		</SvgIcon>
	);
}

export default TransactionsIcon;