import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
		overflow: 'hidden',
		background: theme.palette.white.main,
	},

	header: {
		padding: '24px 0',
		backgroundColor: theme.palette.white.main,
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			bottom: 0,
			right: 0,
			left: 0,
			width: '100%',
			height: '1px',
			background: 'linear-gradient(86.99deg, #955AF7 11.96%, #352CAB 109.69%)',
			opacity: .4,
		},
	},
	container: {
		padding: '0 79px',
		maxWidth: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: '0 auto',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'column-reverse',
		alignItems: 'center',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
		'& .doubleGroup': {
			width: '100%'
		}
	},
	btnRound: {
		minWidth: '153px',
		background: theme.palette.white.main,
		color: '#9C6CFE',
		transition: 'background 0.3s ease-in-out, background-image 0.3s ease-in-out',
		textTransform: 'capitalize',
		padding: '6px 15px',
		display: 'inline-block',
		verticalAlign: 'top',
		textAlign: 'center',
		fontWeight: '600',
		borderRadius: '3rem',
		border: '2px solid #9C6CFE',
		textDecoration: 'none',
		position: 'relative',
		'&:hover': {
			backgroundColor: '#9c6cfe',
			color: theme.palette.white.main,
			textDecoration: 'none',
		},
		marginTop: 10,
		[theme.breakpoints.up('md')]: {
			marginTop: 0,
		},
	},
	login: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'row',
		width: '100%'
	},
	loginContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 32,
		alignItems: 'flex-start',
	},
	left: {
		backgroundColor: theme.palette.white.main,
		color: theme.palette.black.main,
		boxShadow: '2px 0px 10px 1px rgb(0 0 0 / 20%)',
		zIndex: 1,
		width: '100%',
		display: 'inline-flex',
		[theme.breakpoints.up('sm')]: {
			width: 480,
		}
	},
	right: {
		backgroundImage: `url('images/backgrounds/background${Math.floor(Math.random() * 32)}.jpg')`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		display: 'inline-flex',
		flex: 1,
	},
	contentSection: {
		position: 'relative',
		minHeight: 'calc(100vh - 88px)',
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		justifyContent: 'center',
		paddingBottom: 0,
		width: '100%',
	},
	contentWrapper: {
		maxWidth: '548px',
		margin: '100px auto 0 auto',
		padding: '0 15px',
		width: '100%',
	},
	textWrap: {
		marginBottom: '28px',
		fontSize: '18px',
		textAlign: 'center',
		fontWeight: '500',
	},

	textTitle: {
		marginBottom: '20px',
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '26px',
		textTransform: 'none',
		color: '#9C6CFE',
		background: 'linear-gradient(262.91deg, #955AF7 10.5%, #352CAB 118.95%)',
		'-webkit-background-clip': 'text',
		'-webkit-text-fill-color': 'transparent',
	},

	formRow: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
	},
	formGroup: {
		padding: ' 0 12px 24px',
		width: '100%',
	},
	formControl: {
		width: '100%',
		height: '54px',
		fontSize: '18px',
		padding: '8px 16px',
		borderRadius: '8px',
		background: '#F9F9F9',
		border: '1px solid rgba(46, 30, 79, 0.3)',
		fontWeight: '500',
		boxSizing: 'border-box',
		color: theme.palette.black.main,
		'&.MuiInputBase-root': {
			color: theme.palette.black.main,
		}
	},
	doubleGroup: {
		padding: '0 12px 24px',
		width: '50%',
	},
	textButton: {
		color: theme.palette.black.main,
		float: 'right',
		'&:hover': {
			backgroundColor: 'transparent'
		},
	},
	loginButton: {
		borderRadius: '15px',
		fontSize: '20px',
		padding: '13px 10px',
		color: '#f9f9f9',
		width: '100%',
		transition: 'background 0.3s ease-in-out, background-image 0.3s ease-in-out',
		textTransform: 'capitalize',
		background: '#9c6cfe',
		display: 'inline-block',
		textAlign: 'center',
		fontWeight: 600,
		border: '2px solid #9c6fce',
		textDecoration: 'none',
		position: 'relative',
		color: theme.palette.white.main,
		'&[disabled]': {
			backgroundColor: '#c1c1c1 !important',
			cursor: 'default',
			border: '2px solid #c1c1c1',
			backgroundImage: 'none !important',
		},
		'&:hover': {
			backgroundColor: theme.palette.white.main,
			color: '#9c6cfe',
			border: '2px solid #9c6fce !important',
		},
		'& span ': {
			'white-space': 'initial',
		}
	},
	backButton: {
		fontSize: '20px',
		padding: '13px 10px',
		color: theme.palette.black.main,
		width: '100%',
		background: '#ffffff',
		display: 'inline-block',
		textAlign: 'center',
		fontWeight: 600,
		textDecoration: 'none',
		position: 'relative',
		'&:hover': {
			color: theme.palette.black.main,
			background: '#ffffff',
		}
	},
	formError: {
		textAlign: 'center',
		display: 'inline-block',
		width: '100%',
		color: '#FF0000',
		fontWeight: '700',
	},
	formSuccess: {
		textAlign: 'center',
		display: 'inline-block',
		width: '100%',
		color: '#9c6cfe',
		fontWeight: '700',
	}
}));