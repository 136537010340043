import axios from 'axios';
import { useEffect, useRef } from 'react'
import { useAuth } from './Auth0ProviderWithHistory';
import { jwtDecode } from 'jwt-decode';

const AxiosInterceptor = ({ children }) => {
    const INACTIVITY_TIMEOUT_IN_MINUTES = 15;
    const CHECKSESSION_TIMEOUT_IN_MINUTES = 30;
    const QUERY_TIMEOUT_IN_MS = 3 * 60 * 1000;

    const channel = new BroadcastChannel('paybyrd-backoffice');

    const { refreshToken, logout, accessToken } = useAuth();

    const interval = useRef(null);
    const lastCall = useRef(null);

    channel?.addEventListener('message', (event) => {
        if (event?.data?.lastCall > lastCall.current) {
            lastCall.current = event?.data?.lastCall;
        }
    });

    const decodeAccessToken = () => {
        return accessToken ? jwtDecode(accessToken) : {};
    }

    const interceptorFn = async response => {
        if (!response?.config?.params?.Refetch) {
            lastCall.current = new Date().getTime();
            channel?.postMessage({ lastCall: lastCall.current });
        }
        return response;
    }

    const checkInactivity = async () => {
        let currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() - INACTIVITY_TIMEOUT_IN_MINUTES);

        if (lastCall.current < currentDate.getTime()) {
            logout({ returnTo: window.location.origin });
            return true;
        }

        return false;
    }

    const checkAccessToken = async () => {
        const { iat } = decodeAccessToken(accessToken);
        let issuedDate = new Date(iat * 1000);
        issuedDate.setMinutes(issuedDate.getMinutes() + CHECKSESSION_TIMEOUT_IN_MINUTES);

        if (issuedDate < new Date().getTime()) {
            await refreshToken();
        }
    }

    useEffect(() => {
        if (accessToken) {
            interval.current = setInterval(async () => {
                if (await checkInactivity()) {
                    return;
                }

                await checkAccessToken();
            }, QUERY_TIMEOUT_IN_MS);
        }

        const interceptor = axios.interceptors.response.use(interceptorFn, interceptorFn);

        return () => {
            if (interval.current) {
                clearInterval(interval.current)
            }
            return axios.interceptors.response.eject(interceptor);
        }

    }, [accessToken])

    return children;
}

export default AxiosInterceptor;