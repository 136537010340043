import { createSlice } from "@reduxjs/toolkit";
import { createSagaAction } from "saga-toolkit";

import { DEFAULT_PAGE_SIZE } from "services/config";

import { getRangePeriod } from "services/utils";

const name = "payouts";

const defaultPagination = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  returned: 0,
  total: 0,
  totalAvailable: 0,
};

export const actions = {
  FETCH: `${name}/fetch`,
  FETCH_BY_ID: `${name}/fetchById`,
};

export const fetchPayouts = createSagaAction(actions.FETCH);
export const fetchPayout = createSagaAction(actions.FETCH_BY_ID);

export const initialFilters = {
  period: getRangePeriod({ interval: "daily" }),
};

export const payoutsSlice = createSlice({
  name,
  initialState: {
    loading: true,
    settlements: [],
    settlementItems: [],
    error: null,
    success: null,
    settlementsPagination: defaultPagination,
    settlementItemsPagination: defaultPagination,
    filters: initialFilters,
    selectedSettlement: null,
  },
  reducers: {
    setFilters: (state, { payload }) => ({ ...state, filters: payload }),
    selectSettlement: (state, { payload }) => ({ ...state, selectedSettlement: payload }),
    clearSettlement: (state, { payload }) => ({ ...state, selectedSettlement: null }),
  },
  extraReducers: {
    [fetchPayouts.pending]: (state) => {
      if (state.loading === false) {
        state.loading = true;
      }
    },
    [fetchPayouts.fulfilled]: (state, { payload: { data, pagination } }) => ({
      ...state,
      loading: false,
      settlements: data,
      settlementsPagination: pagination,
    }),
    [fetchPayouts.rejected]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [fetchPayout.pending]: (state) => {
      if (state.loading === false) {
        state.loading = true;
      }
    },
    [fetchPayout.fulfilled]: (state, { payload: { data, pagination } }) => ({
      ...state,
      loading: false,
      settlementItems: data,
      settlementItemsPagination: pagination,
    }),
    [fetchPayout.rejected]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
  },
});

export const { setFilters, clear, setSuccess, selectSettlement, clearSettlement } = payoutsSlice.actions;
export default payoutsSlice.reducer;
