import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function PayByLinkIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M6.96 23.16c0 0 0 0 0 0-0.96 0-1.92-0.24-2.76-0.6s-1.68-0.84-2.28-1.56c-1.2-1.2-1.92-3-1.92-4.8s0.72-3.6 2.040-4.92c1.32-1.32 3-2.040 4.92-2.040 1.56 0 2.88 0.48 4.080 1.32l3.72-3.72 3.96-3.84c0 0 0 0 0 0l2.16-2.16c0.48-0.48 1.080-0.36 1.56 0 0.24 0.24 0.36 0.48 0.36 0.72s-0.12 0.6-0.36 0.72l-1.44 1.32 2.52 2.4c0.24 0.24 0.36 0.48 0.36 0.72s-0.24 0.84-0.48 1.080l-3.84 3.72c-0.36 0.36-1.080 0.36-1.56 0l-2.4-2.4-3 3c0.36 0.48 0.6 0.96 0.84 1.44 0.36 0.84 0.6 1.8 0.6 2.64 0 0.96-0.12 1.8-0.48 2.64s-0.84 1.68-1.56 2.28c-0.6 0.6-1.44 1.2-2.28 1.56s-1.8 0.48-2.76 0.48zM7.080 11.4c0 0-0.12 0 0 0-1.32 0-2.64 0.48-3.48 1.44-0.96 0.84-1.44 2.040-1.44 3.36s0.48 2.52 1.32 3.36c0.48 0.48 0.96 0.84 1.56 1.080s1.2 0.36 1.92 0.36c0 0 0 0 0 0 0.6 0 1.32-0.12 1.92-0.36s1.080-0.6 1.56-1.080c0.48-0.48 0.84-0.96 1.080-1.56s0.36-1.2 0.36-1.8c0-0.6-0.12-1.2-0.36-1.8s-0.6-1.080-1.080-1.56c-0.96-0.96-2.16-1.44-3.36-1.44zM17.16 7.56l1.68 1.68 2.28-2.28-1.68-1.56-2.28 2.16z"></path>
		</SvgIcon>
	);
}

export default PayByLinkIcon;