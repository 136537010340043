import React from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useStyles } from "./style";

export function SimpleModal({
	title,
	open = false,
	onClose,
	children,
	close,
	fullscreen,
}) {
	const classes = useStyles();

	return (
		<Modal open={open} onClose={onClose} aria-labelledby="simple-modal-title">
			<Box
				className={classes.paper}
				style={
					fullscreen
						? {
								height: "100%",
								width: "100%",
								maxHeight: "100%",
								maxWidth: "100%",
						  }
						: {}
				}
			>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					{title && (
						<Grid item style={{ display: "inline-flex" }}>
							<Typography variant="h6" id="simple-modal-title">
								{title}
							</Typography>
						</Grid>
					)}
					{!!close && (
						<Grid item>
							<IconButton aria-label="close" onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					)}
				</Grid>
				{children}
				<SimpleModal />
			</Box>
		</Modal>
	);
}

export default SimpleModal;
