import React, { useState, Fragment, useContext } from "react";
import { BananaContext } from "@wikimedia/react.i18n";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";

import { useAuth } from "common/auth/Auth0ProviderWithHistory";
import Alert from "ui/icons/Alert";
import { useExport } from "hooks/useExport";
import { useStyles } from "./style";

export function Export({ filters, reportType, onClose }) {
  const translator = useContext(BananaContext);

  const {
    user: { email },
  } = useAuth();

  const { downloadReport, exporting } = useExport();
  const [exportType, setExportType] = useState("xlsx");
  const classes = useStyles();

  const options = [
    {
      value: "xlsx",
      label: "Xlsx",
    },
    {
      value: "csv",
      label: "Csv",
    },
  ];

  const handleExport = async () => {
    try {
      await downloadReport({ exportType, ...filters }, reportType);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Box m={1}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel disabled component="legend">
            {translator.i18n("choose-file-type")}
          </FormLabel>
          <RadioGroup
            className={classes.options}
            row
            aria-label="position"
            name="position"
            value={exportType}
            onChange={(event) => setExportType(event.target.value)}
          >
            {options.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                labelPlacement="start"
                control={<Radio color="primary" />}
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <Paper elevation={1} className={classes.subtitle}>
          <Typography variant="h5" className={classes.alert}>
            <Alert />
            {translator.i18n("export-details-alert")}
          </Typography>
          <p>{translator.i18n("export-details-help", email)}</p>
        </Paper>
      </Box>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        className={classes.actions}
      >
        <Grid item>
          <Button disabled={exporting} variant="outlined" onClick={onClose}>
            {translator.i18n("cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={exporting}
            variant="contained"
            color="primary"
            onClick={handleExport}
          >
            {translator.i18n("export", "")}
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Export;
