import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function AdministrationIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M11.879 24c-1.212 0-2.303-0.121-3.394-0.485-1.818-0.606-3.515-1.576-4.848-2.909s-2.424-3.030-2.909-4.848-0.727-3.758-0.364-5.697c0.364-1.939 1.212-3.636 2.424-5.212 1.212-1.455 2.788-2.667 4.485-3.515 0.606-0.242 1.212 0 1.455 0.606s0 1.212-0.606 1.455c-1.455 0.606-2.667 1.576-3.636 2.788s-1.697 2.667-1.939 4.242c-0.242 1.576-0.242 3.152 0.242 4.606s1.333 2.909 2.424 4c1.091 1.091 2.545 1.939 4 2.424s3.152 0.485 4.606 0.242c1.576-0.364 3.030-0.97 4.242-1.939s2.182-2.303 2.788-3.758c0.242-0.606 0.848-0.848 1.455-0.606s0.848 0.848 0.606 1.455c-0.727 1.818-1.939 3.394-3.394 4.606s-3.273 2.061-5.212 2.424c-0.97 0-1.697 0.121-2.424 0.121zM22.909 13.091h-10.909c-0.606 0-1.091-0.485-1.091-1.091v-10.909c0-0.606 0.485-1.091 1.091-1.091 1.576 0 3.152 0.364 4.606 0.97s2.788 1.455 3.879 2.545c1.091 1.091 1.939 2.424 2.545 3.879s0.97 3.030 0.97 4.606c0 0.606-0.485 1.091-1.091 1.091zM13.091 10.909h8.727c-0.121-0.97-0.364-1.818-0.727-2.667-0.485-1.212-1.212-2.303-2.182-3.152-0.848-0.97-1.939-1.697-3.152-2.182-0.848-0.364-1.697-0.606-2.667-0.727v8.727z"></path>
		</SvgIcon>
	);
}

export default AdministrationIcon;