export const MODAL_TYPES = {
  REFUND: "RefundModal",
  CAPTURE: "CaptureModal",
  DETAIL: "DetailModal",
  WEBHOOK_DETAIL: "WebhookDetailModal",
  SHIFT_DETAIL: "ShiftDetailModal",
  ONBOARDING_DETAIL: "OnboardingDetail",
  CREATED_SOCIAL_PAYMENT: "CreatedSocialPayment",
  SOCIAL_PAYMENT_DETAIL: "SocialPaymentDetail",
  EXPORT_REPORT_DETAIL: "ExportReportModal",
  STORE_PASSWORD: "ChangeStorePassword",
};
