import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paybyrd: {
    width: 94,
    height: 11,
    marginLeft: 10,
    position: "relative",
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  paybyrdClose: {
    display: "none",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
}));
