import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function PixIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 540 190" style={{ width: 40, height: 40 }}>
      <path
        fill="#37B4AA"
        opacity="1.000000"
        stroke="none"
        d="M11.684195,77.843933 
        C18.532949,70.988243 24.995371,64.253403 31.807676,57.893337 
        C33.505787,56.307949 36.266750,55.438290 38.656963,55.117718 
        C42.092392,54.656963 45.817772,54.311497 49.087128,55.174915 
        C53.212738,56.264469 57.733036,57.813019 60.800484,60.583580 
        C69.440331,68.387222 77.413666,76.927773 85.668732,85.158783 
        C92.296478,91.767204 98.720337,91.747551 105.383453,85.105515 
        C113.167824,77.345772 121.299965,69.893745 128.601044,61.701992 
        C135.778152,53.649323 144.867859,55.113575 153.822113,55.090401 
        C154.379440,55.088959 155.051056,55.581669 155.479706,56.027111 
        C162.607254,63.433826 170.206879,70.459694 176.686478,78.398697 
        C185.613007,89.335732 182.881439,105.468399 173.458939,114.094910 
        C169.777481,117.465347 166.286636,121.055885 162.854645,124.683968 
        C158.164948,129.641663 153.818893,133.833389 145.691406,133.326065 
        C137.441223,132.811081 131.247543,130.063843 125.729797,124.307968 
        C118.471016,116.735924 110.667595,109.674385 103.595863,101.938553 
        C98.495735,96.359474 90.971458,97.617050 86.630547,101.908485 
        C78.577415,109.869804 70.467918,117.781059 62.635902,125.956505 
        C57.897507,130.902695 52.575123,132.906097 45.621868,133.246429 
        C36.959095,133.670441 30.303886,131.680756 24.947363,124.611893 
        C21.370424,119.891495 16.623320,116.071960 12.889278,111.453598 
        C4.275928,100.800354 4.777757,89.534889 11.684195,77.843933 
        z"
      />
      <path fill="#37B4AA" opacity="1.000000" stroke="none" d="M72.249863,125.250137 
        C78.032753,119.463318 83.563393,113.924347 89.099083,108.390457 
        C93.872505,103.618568 97.106232,103.636536 102.050346,108.552773 
        C109.254074,115.715881 116.701279,122.661812 123.519295,130.176987 
        C129.731506,137.024429 137.159164,140.189331 146.889053,140.028305 
        C145.816971,141.342865 145.134659,142.341507 144.295410,143.183777 
        C134.538651,152.975723 124.711861,162.698257 114.995659,172.530121 
        C102.490952,185.183701 86.591995,184.944427 74.216019,173.536865 
        C63.327930,163.500763 53.189159,152.651443 42.737072,142.143784 
        C42.424328,141.829361 42.308727,141.318863 41.622841,139.933731 
        C55.219410,141.721100 64.082802,134.529251 72.249863,125.250137 
        z"
      />
      <path fill="#37B4AA" opacity="1.000000" stroke="none" d="M78.250000,68.750000 
        C72.324249,63.167706 67.213120,56.970657 60.792828,52.778687 
        C55.721924,49.467777 49.041363,48.622162 42.604816,46.528526 
        C44.597038,44.638199 47.234573,42.232075 49.764305,39.717503 
        C58.035923,31.495459 66.488655,23.440527 74.461334,14.936568 
        C85.012016,3.682817 101.083206,3.274564 112.031418,12.901543 
        C124.220016,23.619213 135.167709,35.748096 147.411621,48.020435 
        C135.766052,47.616112 127.880630,52.757717 120.961884,60.398380 
        C114.595314,67.429253 107.629768,73.919434 100.894928,80.614441 
        C97.403419,84.085289 93.632790,84.095451 90.158623,80.666130 
        C86.246086,76.804092 82.384026,72.890923 78.250000,68.750000 
        z"
      />
      <path fill="#9C9B9B" opacity="1.000000" stroke="none" d="M220.000000,175.855072 
          C220.000000,142.709839 219.931442,110.042992 220.042603,77.376770 
          C220.075089,67.826836 222.116104,58.698395 228.052628,50.898613 
          C235.569138,41.022968 245.448868,35.295311 258.006775,35.048054 
          C269.502289,34.821709 281.005798,34.988972 292.505768,35.002399 
          C313.511292,35.026932 330.940369,52.499390 330.996338,73.423500 
          C331.018646,81.756752 330.915985,90.091148 331.017303,98.423225 
          C331.234802,116.309593 317.229095,133.406799 298.899780,135.996414 
          C295.318146,136.502441 291.691467,136.922882 288.081757,136.955170 
          C275.249481,137.069962 262.415497,136.982864 249.582245,137.012283 
          C246.463791,137.019440 243.034653,137.140793 243.130737,132.848587 
          C243.217682,128.964706 246.420502,128.975891 249.389496,128.983414 
          C263.889374,129.020126 278.389435,129.015167 292.889374,128.995789 
          C307.738678,128.975952 322.236786,115.662415 322.912506,100.827385 
          C323.384552,90.464035 323.383148,80.043732 322.900146,69.681175 
          C322.288025,56.548843 307.347626,43.032467 294.099976,43.005226 
          C282.100037,42.980553 270.099976,42.985928 258.100037,43.003735 
          C244.406036,43.024059 231.144104,54.360077 229.020416,67.999863 
          C228.439102,71.733444 228.051712,75.539505 228.043182,79.313362 
          C227.968475,112.313133 228.000107,145.313141 227.999649,178.313080 
          C227.999634,179.479736 228.355392,180.814636 227.908218,181.774261 
          C227.207932,183.277191 225.885208,185.687271 224.966537,185.625473 
          C223.379501,185.518723 221.213394,184.128647 220.548599,182.693893 
          C219.707565,180.878799 220.125229,178.480469 220.000000,175.855072 
          z"
      />
      <path fill="#9C9B9B" opacity="1.000000" stroke="none" d="M475.249542,103.749512 
          C472.524109,101.030716 470.181732,98.407661 467.539032,96.133682 
          C464.422882,93.452332 461.354706,92.938507 458.011810,96.381210 
          C448.734558,105.935440 438.936554,114.996849 429.888123,124.757118 
          C419.033630,136.465469 405.322479,137.940186 390.757996,136.807159 
          C389.425964,136.703552 387.059875,134.018066 387.302612,133.085648 
          C387.716675,131.495178 389.680908,129.428040 391.241089,129.191544 
          C394.984680,128.624054 398.874939,128.901428 402.700226,129.021698 
          C410.065399,129.253265 416.085724,126.596764 421.233215,121.329666 
          C430.658051,111.685867 440.454834,102.400871 449.748199,92.634781 
          C456.810638,85.213127 467.170685,83.584602 475.080688,92.365044 
          C483.649384,101.876617 493.288940,110.414749 502.096130,119.723091 
          C508.327881,126.309433 515.592651,129.499649 524.585754,129.019150 
          C526.909668,128.894989 529.335144,128.629883 531.543579,129.151276 
          C532.862122,129.462585 534.780884,131.192184 534.763306,132.256683 
          C534.738098,133.782150 533.278320,136.532516 532.279541,136.602646 
          C520.627319,137.420975 509.032684,137.407379 499.877563,128.359406 
          C491.707092,120.284538 483.622284,112.123001 475.249542,103.749512 
          z"
      />
      <path fill="#9C9B9B" opacity="1.000000" stroke="none" d="M405.398132,42.240395 
          C400.141907,42.058014 395.293396,42.234337 390.490234,41.847538 
          C389.264801,41.748852 387.244781,39.839928 387.272858,38.808544 
          C387.307953,37.519474 389.099792,35.170589 390.102661,35.186680 
          C400.249664,35.349487 410.780609,33.062958 420.160217,39.063038 
          C421.954956,40.211117 423.801910,41.381832 425.303802,42.866741 
          C435.731903,53.177036 446.070496,63.577827 456.447998,73.939400 
          C461.205688,78.689789 463.757812,78.721550 468.414185,74.075005 
          C477.968903,64.540367 487.575806,55.056618 497.030518,45.423618 
          C503.998840,38.323917 512.167908,34.452518 522.253601,34.979073 
          C524.912659,35.117901 527.588562,35.083931 530.250610,34.977684 
          C533.104431,34.863785 535.102295,35.316017 535.057312,38.940514 
          C535.012817,42.530304 532.774780,42.930786 530.130676,42.982288 
          C526.799072,43.047184 523.464294,43.048069 520.132446,42.989174 
          C514.367004,42.887276 509.540649,44.907520 505.472412,49.006393 
          C495.494110,59.059898 485.296448,68.900551 475.485138,79.113220 
          C468.501465,86.382576 457.848389,87.375153 450.342529,79.059006 
          C441.644775,69.422424 431.942078,60.700653 422.936737,51.330833 
          C418.147278,46.347538 412.619293,43.281963 405.398132,42.240395 
          z"
        />
      <path fill="#9C9B9B" opacity="1.000000" stroke="none" d="M343.058655,35.000000 
          C351.257202,34.700760 359.128906,34.344532 364.120575,41.778625 
          C365.956177,44.512333 367.799591,47.942375 367.833282,51.074230 
          C368.123260,78.033035 367.994598,104.996277 368.008331,131.958191 
          C368.009644,134.554352 368.221344,137.103729 364.369568,137.017365 
          C360.404968,136.928482 361.011627,134.128860 361.010040,131.698654 
          C360.993805,106.733925 361.001587,81.769180 360.999237,56.804447 
          C360.998322,46.984692 357.041473,43.027348 347.303589,42.986271 
          C343.653503,42.970879 339.905945,43.255630 336.397247,42.500397 
          C334.731262,42.141804 333.521545,39.663559 332.105164,38.145557 
          C333.764404,37.097412 335.306946,35.673153 337.117126,35.111343 
          C338.787109,34.593060 340.744385,35.000378 343.058655,35.000000 
          z"
      />
      <path fill="#37B4AA" opacity="1.000000" stroke="none" d="M370.226990,24.275890 
          C364.885742,29.584625 364.073944,29.610554 359.122406,24.604996 
          C357.382660,22.846260 355.324463,21.194300 354.213013,19.073595 
          C352.737854,16.258875 356.468567,10.115857 361.079620,7.535165 
          C364.007477,5.896523 366.979462,6.154026 369.379639,8.881716 
          C369.708954,9.255976 370.085358,9.588825 370.439728,9.941083 
          C376.608154,16.072897 376.614258,17.897972 370.226990,24.275890 
          z"
      />
    </SvgIcon>
  );
}

export default PixIcon;
