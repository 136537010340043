import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
	},
	input: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 12,
		padding: 24,
		overflow: 'hidden',
		'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
		}
	},
	title: {
		display: 'flex',
		textTransform: 'uppercase',
		height: 20,
	},
	helperText: {
		position: 'absolute',
		top: 'calc(100% + 10px)',
		width: '100%',
		display: 'flex',
	},
}));