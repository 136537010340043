import React, { Fragment, useState, useContext, useRef } from "react";
import { BananaContext } from "@wikimedia/react.i18n";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Collapse from "@material-ui/core/Collapse";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import DashboardIcon from "common/ui/icons/DashboardIcon";
import TransactionsIcon from "common/ui/icons/TransactionsIcon";
import StatisticsIcon from "common/ui/icons/StatisticsIcon";
import AdministrationIcon from "common/ui/icons/AdministrationIcon";
import ChargebackIcon from "common/ui/icons/ChargebackIcon";
import SettingsIcon from "common/ui/icons/SettingsIcon";
import PayByLinkIcon from "common/ui/icons/PayByLinkIcon";
import DevelopersIcon from "common/ui/icons/DevelopersIcon";
import LogoutIcon from "common/ui/icons/LogoutIcon";
import AnalyticsIcon from "common/ui/icons/AnalyticsIcon";
import InvitesIcon from "common/ui/icons/InvitesIcon";
import ManagementIcon from "common/ui/icons/ManagementIcon";
import OrdersIcon from "common/ui/icons/OrdersIcon";
import PartnersIcon from "common/ui/icons/PartnersIcon";
import PaymentsIcon from "common/ui/icons/PaymentsIcon";
import PayoutsIcon from "common/ui/icons/PayoutsIcon";
import ShiftsIcon from "common/ui/icons/ShiftsIcon";
import UserIcon from "common/ui/icons/UserIcon";
import WebhookIcon from "common/ui/icons/WebhookIcon";
import ApiIcon from "common/ui/icons/ApiIcon";
import OnboardingIcon from "common/ui/icons/OnboardingIcon";
import StoreIcon from "common/ui/icons/StoreIcon";
import PaybyrdLogo from "common/ui/PaybyrdLogo";
import MenuItem from "./MenuItem";

import { PERMISSIONS } from "services/config";
import { toggleMenu } from "store/reducers/ui";
import { useUser } from "hooks/useUser";
import { useAuth } from "common/auth/Auth0ProviderWithHistory";

import { useStyles } from "./style";

export function Menu({ open, offset }) {
  const translator = useContext(BananaContext);
  const { userHasScope } = useUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const { logout, user } = useAuth();
  const classes = useStyles({ offset });
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const container = window !== undefined ? () => window.document.body : undefined;
  const [currentMenuIndex, setCollapsableMenuIndex] = useState(null);
  const isFirstLoad = useRef(true);

  const isRouteSelected = (route, parentIndex) => {
    if (
      pathname.includes(route) &&
      (parentIndex || parentIndex === 0) &&
      currentMenuIndex !== parentIndex &&
      isFirstLoad.current
    ) {
      setCollapsableMenuIndex(parentIndex);
      isFirstLoad.current = false;
    }

    return pathname.includes(route);
  };
  const handleItemClick = (route, parentIndex) => {
    if (typeof parentIndex !== "number") {
      setCollapsableMenuIndex(parentIndex);
    }

    return history.push(`/${route}`);
  };
  const handleDrawerToggle = () => dispatch(toggleMenu());
  const handleDrawerMobileToggle = () => {
    dispatch(toggleMenu(true));
    return setMobileOpen(!mobileOpen);
  };

  const configureAnalyticsMenu = (menuItems) => {
    menuItems.push(
      {
        label: translator.i18n("analytics-title"),
        icon: <AnalyticsIcon />,
        slug: "analytics",
        children: [
          {
            label: translator.i18n("statistics-title"),
            icon: <StatisticsIcon />,
            slug: "statistics",
            permissions: PERMISSIONS.REPORTS_STATISTICS,
            children: [],
          },
        ],
      }
    );
  }

  const configureManagementMenu = (menuItems) => {
    menuItems.push(
      {
        label: translator.i18n("management-title"),
        icon: <ManagementIcon />,
        slug: "management",
        children: [
          {
            label: translator.i18n("administration-title"),
            icon: <AdministrationIcon />,
            slug: "administration",
            permissions: PERMISSIONS.ADMINISTRATION,
            children: [],
          },
          {
            label: translator.i18n("shifts-title"),
            icon: <ShiftsIcon />,
            slug: "shifts",
            permissions: PERMISSIONS.SHIFTS,
            children: [],
          },
          {
            label: translator.i18n("orders-title"),
            icon: <OrdersIcon />,
            slug: "orders",
            permissions: PERMISSIONS.REPORTS_TRANSACTIONS,
            children: [],
          },
          {
            label: translator.i18n("chargeback-title"),
            icon: <ChargebackIcon />,
            slug: "chargebacks",
            children: [],
          },
        ],
      }
    );
  }

  const configurePaymentsMenu = (menuItems) => {
    menuItems.push(
      {
        label: translator.i18n("payments-title"),
        icon: <PaymentsIcon />,
        slug: "payments",
        children: [
          {
            label: translator.i18n("transactions-title"),
            icon: <TransactionsIcon />,
            slug: "transactions",
            permissions: PERMISSIONS.REPORTS_TRANSACTIONS,
            children: [],
          },
          {
            label: translator.i18n("pay-by-link-title"),
            icon: <PayByLinkIcon />,
            slug: "paybylink",
            permissions: PERMISSIONS.PAYBYLINK,
            children: [],
          },
          {
            label: translator.i18n("payouts-title"),
            icon: <PayoutsIcon />,
            slug: "payouts",
            permissions: PERMISSIONS.ADMINISTRATION_INVOICES,
            children: [],
          },
          {
            label: translator.i18n("settlements"),
            icon: <PayoutsIcon />,
            slug: "settlements",
            permissions: PERMISSIONS.ADMINISTRATION_INVOICES,
            children: [],
          },
        ],
      }
    );
  }

  const configurePartnersMenu = (menuItems) => {
    if (!user.isPaybyrd && !user.isPartner) {
      return;
    }

    menuItems.push(
      {
        label: translator.i18n("partners-title"),
        icon: <PartnersIcon />,
        slug: "partners",
        children: [
          {
            label: translator.i18n("invite-merchants"),
            icon: <InvitesIcon />,
            slug: "invite",
            children: [],
          },
          {
            label: translator.i18n("onboarding"),
            icon: <OnboardingIcon />,
            slug: "onboarding",
            children: [],
          },
        ],
      }
    );
  }

  const configureSettingsMenu = (menuItems) => {
    menuItems.push(
      {
        label: translator.i18n("settings-title"),
        icon: <SettingsIcon />,
        slug: "settings",
        children: [
          {
            label: translator.i18n("user"),
            icon: <UserIcon />,
            slug: "user",
            children: [],
          },
          {
            label: translator.i18n("units"),
            icon: <StoreIcon/>,
            slug: "stores",
            children: [],
          },
        ],
      }
    );
  }

  const configureDevelopersMenu = (menuItems) => {
    menuItems.push(
      {
        label: translator.i18n("developers-title"),
        icon: <DevelopersIcon />,
        slug: "developers",
        permissions: PERMISSIONS.DEVELOPERS,
        children: [
          {
            label: translator.i18n("api-keys"),
            icon: <ApiIcon />,
            slug: "apikeys",
            children: [],
          },
          {
            label: translator.i18n("webhooks"),
            icon: <WebhookIcon />,
            slug: "webhooks",
            children: [],
          },
        ],
      }
    );
  }

  const menuItems = [
    {
      label: translator.i18n("dashboard-title"),
      icon: <DashboardIcon />,
      slug: "dashboard",
      children: [],
    }
  ];

  configureAnalyticsMenu(menuItems);
  configureManagementMenu(menuItems);
  configurePaymentsMenu(menuItems);
  // Hiding partners menu for now
  // configurePartnersMenu(menuItems);
  configureSettingsMenu(menuItems);
  configureDevelopersMenu(menuItems);

  const handleMenuClick = (index) => {
    if (currentMenuIndex === index) {
      setCollapsableMenuIndex(null);
    } else {
      setCollapsableMenuIndex(index);
    }
  };

  const renderTopList = () => (
    <List disablePadding={true} style={{ marginTop: 56 }}>
      {menuItems.map(({ label, icon, slug, permissions, children }, index) => {
        if (children.length) {
          const containsPermissions = children.some((child) => child.permissions);
          const hasSomePermission = children.some((child) => userHasScope(child.permissions));

          if (!containsPermissions || hasSomePermission) {
            return (
              <div key={index}>
                <MenuItem
                  key={label}
                  label={label}
                  icon={icon}
                  open={open}
                  slug={slug}
                  selected={isRouteSelected(slug)}
                  onClick={() => handleMenuClick(index)}
                />
                <Collapse
                  in={index === currentMenuIndex}
                  timeout="auto"
                  unmountOnExit
                  className={!open ? classes.menuClosed : ""}
                >
                  {children.map((child, key) => {
                    if (!child.permissions) {
                      return (
                        <MenuItem
                          key={key}
                          label={child.label}
                          icon={child.icon}
                          open={open}
                          slug={child.slug}
                          selected={isRouteSelected(child.slug, index)}
                          onClick={(route) => handleItemClick(route, index)}
                        />
                      );
                    }

                    return (
                      userHasScope(child.permissions) && (
                        <MenuItem
                          key={child.label}
                          label={child.label}
                          icon={child.icon}
                          open
                          slug={child.slug}
                          selected={isRouteSelected(child.slug, index)}
                          onClick={(route) => handleItemClick(route, index)}
                        />
                      )
                    );
                  })}
                </Collapse>
              </div>
            );
          }

          return;
        }

        if (!permissions) {
          return (
            <MenuItem
              key={label}
              label={label}
              icon={icon}
              open={open}
              slug={slug}
              selected={isRouteSelected(slug)}
              onClick={handleItemClick}
            />
          );
        }

        return (
          userHasScope(permissions) && (
            <MenuItem
              key={label}
              label={label}
              icon={icon}
              open={open}
              slug={slug}
              selected={isRouteSelected(slug)}
              onClick={handleItemClick}
            />
          )
        );
      })}
      <MenuItem
        data-testid="logout"
        label={translator.i18n("logout")}
        icon={<LogoutIcon />}
        open={open}
        onClick={() =>
          logout({
            returnTo: window.location.origin,
          })
        }
      />
    </List>
  );

  return (
    <Fragment>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <Box>
            <Box
              className={clsx(classes.logoContainer, {
                [classes.logoContainerClosed]: !open,
              })}
            >
              <PaybyrdLogo open={open} onClick={handleDrawerToggle} />
              <span title={translator.i18n("click-to-toggle")}>
                <ChevronLeft className={classes.toggleMenuIcon} onClick={handleDrawerToggle} />
              </span>
            </Box>
            {renderTopList()}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar classes={{ root: classes.toolbar, gutters: classes.toolbarGutters }}>
            <PaybyrdLogo onClick={handleDrawerToggle} />
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerMobileToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerMobileToggle}
          classes={{
            paper: clsx(classes.drawerPaper, classes.drawerPaperMobile),
          }}
          ModalProps={{ keepMounted: true }}
        >
          {renderTopList()}
        </Drawer>
      </Hidden>
    </Fragment>
  );
}

export default Menu;
