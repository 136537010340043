import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function PaybyrdLoginLogo(props) {
	return (
		<SvgIcon {...props} viewBox="310 430 1310 200" style={{ width: 156, height: 27 }}>
			<g>
				<g>
					<path d="M634.81,475.01c-12.5-17.92-32.28-27.74-52.56-28.25v-0.03h-97.87v0.37c0.24,0.89,0.5,1.78,0.81,2.67
						c4.37,12.74,15.07,25.73,26.77,31.55c24.38,12.12,52.03-5.06,76.84,5.47c9.42,4,16.95,11.92,20.97,21.27
						c0.95,2.21,5.59,16.15,2.97,14.75c-18.04-9.64-41.61,0.26-56.97,13.01c-7.26,6.02-14.11,12.57-20.2,19.77
						c-3.06,3.62-5.93,7.4-8.56,11.34c-1.32,1.98-2.59,4.01-3.79,6.07c-0.83,1.43-1.46,2.59-1.69,3.02c-0.64,1.19-1.2,2.27-1.67,3.21
						c-0.78-0.02-1.97-0.04-3.42-0.05c-2.83-0.02-4.93,0.02-5.8,0.03c-3.32,0.01-6.64,0.04-9.96,0.04c-7.42,0-24.84,0-36.13,0
						l-23.49,54.46h32.35c13.97,0,25.3-9.54,31.38-21.76c5.03-10.9,10.05-21.81,15.08-32.71c20.8-0.13,41.6-0.25,62.4-0.38v-0.02
						c12.53-0.3,25.1-4.14,36.15-11.85C648.33,546.12,655.68,504.94,634.81,475.01z"></path>
					<path d="M711.46,578.63v-119.6h71.48c23.35,0,38.57,13.98,38.57,35.92c0,22.12-15.22,35.74-38.57,35.74h-48.48v47.95H711.46z
						M734.46,509.45h46.53c11.32,0,16.98-5.13,16.98-14.51c0-9.38-5.66-14.68-16.98-14.68h-46.53V509.45z"></path>
					<path d="M1034.34,486.45h24.77l-52.37,113.76c-4.25,9.2-8.85,17.34-24.59,17.34h-30.96v-17.87h25.3c5.66,0,8.85-0.88,11.85-7.78
						l5.48-12.38l-44.41-93.06h25.12l27.6,62.45h5.13L1034.34,486.45z"></path>
					<path d="M1175.29,532.63c0,27.07-17.87,48.12-46.18,48.12c-13.27,0-23.88-5.48-31.49-14.15h-4.42l-0.53,12.03h-21.23V446.29h22.29
						v51.31h4.78c7.61-8.14,17.69-13.27,30.61-13.27C1157.24,484.33,1175.29,505.38,1175.29,532.63z M1152.64,532.63
						c0-17.52-12.56-28.84-29.19-28.84c-13.8,0-25.3,7.43-29.72,17.69v22.11c4.6,10.26,16.1,17.69,29.9,17.69
						C1140.25,561.29,1152.64,549.97,1152.64,532.63z"></path>
					<path d="M1265.23,486.45H1290l-52.37,113.76c-4.25,9.2-8.85,17.34-24.59,17.34h-30.96v-17.87h25.3c5.66,0,8.85-0.88,11.85-7.78
						l5.48-12.38l-44.41-93.06h25.12l27.6,62.45h5.13L1265.23,486.45z"></path>
					<path d="M1330.05,499.72c7.78-10.26,19.82-15.92,36.8-15.22v21.05c-19.64-1.06-33.97,4.6-41.22,16.1v56.97h-22.29v-92.18h21.23
						l0.53,13.27H1330.05z"></path>
					<path d="M1456.66,446.29h22.29v132.34h-21.23l-0.53-12.03h-4.42c-7.61,8.67-18.05,14.15-31.32,14.15
						c-28.31,0-46.35-21.05-46.35-48.12c0-27.25,18.05-48.3,46.35-48.3c12.92,0,23,5.13,30.61,13.27h4.6V446.29z M1426.76,561.29
						c13.8,0,25.3-7.43,29.9-17.69v-22.29c-4.6-10.08-15.92-17.52-29.72-17.52c-16.45,0-29.19,11.32-29.19,28.84
						C1397.75,549.97,1410.31,561.29,1426.76,561.29z"></path>
					<path d="M913.12,486.43c7.43,0,14.86,0,22.29,0c0,30.73,0,61.47,0,92.2h-21.23l-0.53-12.03h-4.42
						c-7.61,8.67-18.05,14.15-31.32,14.15c-28.31,0-46.35-21.05-46.35-48.12c0-27.25,18.05-48.3,46.35-48.3
						c12.92,0,23,5.13,30.61,13.27h4.6C913.12,493.87,913.12,490.15,913.12,486.43z M883.22,561.29c13.8,0,25.3-7.43,29.9-17.69v-22.29
						c-4.6-10.08-15.92-17.52-29.72-17.52c-16.45,0-29.19,11.32-29.19,28.84C854.21,549.97,866.77,561.29,883.22,561.29z"></path>
				</g>
				<g>
					<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="441.0464" y1="540.2205" x2="646.6885" y2="540.2205">
						<stop offset="0" style={{ stopColor: '#5237DB' }}></stop>
						<stop offset="0.0704" style={{ stopColor: '#573EDD' }}></stop>
						<stop offset="0.4584" style={{ stopColor: '#705FE6' }}></stop>
						<stop offset="0.7821" style={{ stopColor: '#7F73EB' }}></stop>
						<stop offset="1" style={{ stopColor: '#857AED' }}></stop>
						<stop offset="1" style={{ stopColor: '#715EE6' }}></stop>
					</linearGradient>
					<path fill="url(#SVGID_1_)" d="M634.81,475.01c-12.5-17.92-32.28-27.74-52.56-28.25v-0.03h-97.87v0.37c0.24,0.89,0.5,1.78,0.81,2.67
						c4.37,12.74,15.07,25.73,26.77,31.55c24.38,12.12,52.03-5.06,76.84,5.47c9.42,4,16.95,11.92,20.97,21.27
						c0.95,2.21,5.59,16.15,2.97,14.75c-18.04-9.64-41.61,0.26-56.97,13.01c-7.26,6.02-14.11,12.57-20.2,19.77
						c-3.06,3.62-5.93,7.4-8.56,11.34c-1.32,1.98-2.59,4.01-3.79,6.07c-0.83,1.43-1.46,2.59-1.69,3.02c-0.64,1.19-1.2,2.27-1.67,3.21
						c-0.78-0.02-1.97-0.04-3.42-0.05c-2.83-0.02-4.93,0.02-5.8,0.03c-3.32,0.01-6.64,0.04-9.96,0.04c-7.42,0-24.84,0-36.13,0
						l-23.49,54.46h32.35c13.97,0,25.3-9.54,31.38-21.76c5.03-10.9,10.05-21.81,15.08-32.71c20.8-0.13,41.6-0.25,62.4-0.38v-0.02
						c12.53-0.3,25.1-4.14,36.15-11.85C648.33,546.12,655.68,504.94,634.81,475.01z"></path>
					<path fill="#000000" d="M711.46,578.63v-119.6h71.48c23.35,0,38.57,13.98,38.57,35.92c0,22.12-15.22,35.74-38.57,35.74h-48.48v47.95H711.46z
						M734.46,509.45h46.53c11.32,0,16.98-5.13,16.98-14.51c0-9.38-5.66-14.68-16.98-14.68h-46.53V509.45z"></path>
					<path fill="#000000" d="M1034.34,486.45h24.77l-52.37,113.76c-4.25,9.2-8.85,17.34-24.59,17.34h-30.96v-17.87h25.3c5.66,0,8.85-0.88,11.85-7.78
						l5.48-12.38l-44.41-93.06h25.12l27.6,62.45h5.13L1034.34,486.45z"></path>
					<path fill="#000000" d="M1175.29,532.63c0,27.07-17.87,48.12-46.18,48.12c-13.27,0-23.88-5.48-31.49-14.15h-4.42l-0.53,12.03h-21.23V446.29h22.29
						v51.31h4.78c7.61-8.14,17.69-13.27,30.61-13.27C1157.24,484.33,1175.29,505.38,1175.29,532.63z M1152.64,532.63
						c0-17.52-12.56-28.84-29.19-28.84c-13.8,0-25.3,7.43-29.72,17.69v22.11c4.6,10.26,16.1,17.69,29.9,17.69
						C1140.25,561.29,1152.64,549.97,1152.64,532.63z"></path>
					<path fill="#000000" d="M1265.23,486.45H1290l-52.37,113.76c-4.25,9.2-8.85,17.34-24.59,17.34h-30.96v-17.87h25.3c5.66,0,8.85-0.88,11.85-7.78
						l5.48-12.38l-44.41-93.06h25.12l27.6,62.45h5.13L1265.23,486.45z"></path>
					<path fill="#000000" d="M1330.05,499.72c7.78-10.26,19.82-15.92,36.8-15.22v21.05c-19.64-1.06-33.97,4.6-41.22,16.1v56.97h-22.29v-92.18h21.23
						l0.53,13.27H1330.05z"></path>
					<path fill="#000000" d="M1456.66,446.29h22.29v132.34h-21.23l-0.53-12.03h-4.42c-7.61,8.67-18.05,14.15-31.32,14.15
						c-28.31,0-46.35-21.05-46.35-48.12c0-27.25,18.05-48.3,46.35-48.3c12.92,0,23,5.13,30.61,13.27h4.6V446.29z M1426.76,561.29
						c13.8,0,25.3-7.43,29.9-17.69v-22.29c-4.6-10.08-15.92-17.52-29.72-17.52c-16.45,0-29.19,11.32-29.19,28.84
						C1397.75,549.97,1410.31,561.29,1426.76,561.29z"></path>
					<path fill="#000000" d="M913.12,486.43c7.43,0,14.86,0,22.29,0c0,30.73,0,61.47,0,92.2h-21.23l-0.53-12.03h-4.42
						c-7.61,8.67-18.05,14.15-31.32,14.15c-28.31,0-46.35-21.05-46.35-48.12c0-27.25,18.05-48.3,46.35-48.3
						c12.92,0,23,5.13,30.61,13.27h4.6C913.12,493.87,913.12,490.15,913.12,486.43z M883.22,561.29c13.8,0,25.3-7.43,29.9-17.69v-22.29
						c-4.6-10.08-15.92-17.52-29.72-17.52c-16.45,0-29.19,11.32-29.19,28.84C854.21,549.97,866.77,561.29,883.22,561.29z"></path>
				</g>
			</g>
		</SvgIcon>
	);
}

export default PaybyrdLoginLogo;
