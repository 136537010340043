import { call, fork } from "redux-saga/effects";
import { takeLatestAsync } from "saga-toolkit";

import { DEFAULT_PAGE_SIZE } from "services/config";

import { api } from "api";
import { fetch } from "./reducer";

const getParamsFromFilters = ({
  query,
  period: { startDate, endDate },
  statuses,
  channel,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  journalType,
  amount,
  ...filters
}) => {
  return {
    AmountFrom: amount?.min,
    AmountTo: amount?.max,
    GroupId: filters.groupId ? Object.keys(filters.groupId) : null,
    StoreId: filters.storeId,
    MerchantId: filters.merchantId ? Object.keys(filters.merchantId) : null,
    PageSize: pageSize,
    AcquirerAuthorizationCode: filters.aquirerAuthorizationCode,
    ApplicationId: filters.applicationId,
    CardHolderName: filters.cardHolderName,
    CardNumber: filters.cardNumber,
    ClientFrequentFlightNumber: filters.clientFrequentFlyerNumber,
    Country: filters.country ? Object.keys(filters.country) : null,
    Iban: filters.iban,
    IsOneClickPayment: filters.isOneClickPayment,
    LastFourCardNumber: filters.lastFourCardNumber,
    OrderRef: filters.orderRef,
    Page: pageNumber,
    PhoneNumber: filters.phoneNumber ? `${filters.phoneNumber?.countryCode}${filters.phoneNumber?.number}` : null,
    Pnr: filters.pnr,
    ShopperEmail: filters.shopperEmail,
    ShopperIp: filters.shopperIp,
    OrderDateFrom: startDate,
    OrderDateTo: endDate,
    PaymentId: filters.paymentId,
    ShiftId: filters.shiftId,
    OrderId: filters.orderId,
    outputType: filters.exportType,
  };
};

export const getParams = (filters) => ({
  ...getParamsFromFilters(filters),
});

function* handleFetch({ meta: { arg } }) {
  try {
    const data = yield call(api.getOrders, getParams(arg));
    return data.value ? data.value : data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export const suitParamsToExport = (params) => {
  const normalizedParams = getParamsFromFilters(params);

  const normalizeArray = (param) => {
    let newParam = param?.length > 0 ? param : null;
    if (newParam) {
      newParam = Array.isArray(param) ? param : [param];
    }
    return newParam;
  }

  return {
    ...normalizedParams,
    Currency: normalizedParams.Currency?.split(','),
    TransactionType: normalizeArray(normalizedParams.TransactionType),
    ApplicationId: normalizeArray(normalizedParams.ApplicationId),
    GroupId: normalizeArray(normalizedParams.GroupId),
    MerchantId: normalizeArray(normalizedParams.MerchantId),
    StoreId: normalizeArray(normalizedParams.StoreId),
  }

};

function* fetchSaga() {
  yield takeLatestAsync(fetch.type, handleFetch);
}

export function* ordersSaga() {
  yield fork(fetchSaga);
}
