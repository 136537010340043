import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	input: {
		fontSize: 36,
		lineHeight: .9,
	},
	error: {
		borderBottom: `1px solid ${theme.palette.red.main}`
	},
	errorField: {
		marginTop: 5,
		color: theme.palette.red.main,
	},
}));