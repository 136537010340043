import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export function ChargebackIcon(props) {
	return (
		<SvgIcon
			{...props}
			viewBox="100 40 520 520"
			style={{ width: 24, height: 24 }}
			fill="#FFFFFF"
		>
			<path d="m348.91 361.97c-19.184-5.7969-32.074-18.32-32.074-31.156 0-10.305-8.3828-18.684-18.684-18.684s-18.68 8.3789-18.68 18.684c0 32.914 29.734 62.254 70.699 69.762l2.2031 0.40625v17.605c0 10.297 8.3789 18.676 18.68 18.676 10.301 0 18.684-8.3789 18.684-18.676v-17.605l2.207-0.40625c40.957-7.5234 70.688-36.863 70.688-69.766 0-19.559-9.9258-37.543-27.945-50.633-11.934-8.668-26.684-14.531-42.656-16.949l-2.2891-0.34766v-65.98l3.4688 1.0508c19.176 5.7969 32.062 18.32 32.062 31.156 0 10.297 8.3828 18.676 18.684 18.676 10.301 0 18.68-8.3789 18.68-18.676 0-32.906-29.73-62.246-70.688-69.762l-2.207-0.40625v-17.582c0-10.297-8.3828-18.676-18.684-18.676s-18.68 8.3789-18.68 18.676v17.578l-2.2031 0.40625c-40.965 7.5117-70.699 36.855-70.699 69.766v1.3086c0 19.559 9.9219 37.547 27.938 50.645 11.945 8.668 26.703 14.531 42.68 16.949l2.2852 0.34375v64.668zm40.832-61.289 3.3438 0.83203c7.4766 1.8672 14.09 4.8555 19.645 8.8906 8.0898 5.8828 12.543 13.133 12.543 20.41 0 12.832-12.883 25.348-32.059 31.152l-3.4727 1.0508zm-40.707-40.961c-7.4961-1.8711-14.113-4.8633-19.66-8.8945-8.0859-5.8828-12.539-13.133-12.539-20.41v-1.3086c0-12.844 12.891-25.363 32.074-31.156l3.4688-1.0469v63.652z" />
			<path d="m615.29 261.2c-9.0781-120.75-107.27-217.82-228.12-225.6-145.92-9.3906-267.04 109.86-260.78 255.2h-33.105c-7.5352 0-11.926 8.5078-7.5586 14.648l55.355 77.828c3.6992 5.1992 11.418 5.1992 15.117 0l55.352-77.828c4.3672-6.1406-0.023438-14.648-7.5586-14.648h-33.387c-6.2656-119.41 92.426-217.89 211.89-211.23 103.22 5.7617 186.28 91.23 189.25 194.57 3.2617 113.36-88.027 206.62-200.7 206.62-49.699 0-96.527-18.027-132.92-50.332-9.6016-8.5195-24.332-7.3359-32.133 2.8633l-0.27344 0.35938c-6.7617 8.8398-5.4727 22.109 2.8555 29.488 44.566 39.48 102.57 61.789 162.47 61.789 141.29 0 255.02-120.22 244.24-263.72z" />
		</SvgIcon>
	);
}

export default ChargebackIcon;
