import { useState } from "react";
import { useDispatch } from "react-redux";

import { transactionCapture } from "features/transactions/reducer";

export function useCapture() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const capture = async (paymentId, amount, merchantId) => {
    setLoading(true);
    try {
      const { error } = await dispatch(
        transactionCapture({ paymentId, amount, merchantId })
      );

      if (error) {
        throw error;
      }
    } catch (error) {
      console.log(`${paymentId} failed!`);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, capture };
}
