import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function ManagementIcon(props) {
	return (
		<SvgIcon {...props}>
			<g>
				<rect width="24" height="24" fill="none"/>
				<path d="M15.06934,15.543a.89981.89981,0,0,1-1.26172.16992l-1.77777-1.35583-1.80133,1.35876a.9002.9002,0,1,1-1.084-1.4375l1.95508-1.47473V10.89893a.90039.90039,0,0,1,1.80078,0v1.85791l1.999,1.52441A.90072.90072,0,0,1,15.06934,15.543ZM7.90039,5.03467a4.03076,4.03076,0,1,1,4.03125,4.03076A4.03553,4.03553,0,0,1,7.90039,5.03467Zm1.80078,0A2.23,2.23,0,1,0,11.93164,2.8042,2.233,2.233,0,0,0,9.70117,5.03467ZM23.002,18.94824A4.03076,4.03076,0,1,1,18.9707,14.918,4.036,4.036,0,0,1,23.002,18.94824Zm-1.80078,0a2.23,2.23,0,1,0-2.23047,2.23047A2.232,2.232,0,0,0,21.20117,18.94824Zm-12.13086,0A4.03076,4.03076,0,1,1,5.04,14.918,4.03584,4.03584,0,0,1,9.07031,18.94824Zm-1.80078,0A2.23,2.23,0,1,0,5.04,21.17871,2.23183,2.23183,0,0,0,7.26953,18.94824Z" fill="#83858f"/>
			</g>
		</SvgIcon>
	);
}

export default ManagementIcon;