import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function JCBIcon(props) {
	return (
		<SvgIcon {...props} viewBox="110 -70 40 340" style={{ width:40, height:40 }}>
            <defs>
                <linearGradient x1="-57.5270968%" y1="50.1241953%" x2="232.39121%" y2="50.1241953%" id="linearGradient-1">
                    <stop stopColor="#007940" offset="0%"/>
                    <stop stopColor="#00873F" offset="22.85%"/>
                    <stop stopColor="#40A737" offset="74.33%"/>
                    <stop stopColor="#5CB531" offset="100%"/>
                </linearGradient>
                <linearGradient x1="0.182516704%" y1="49.95997%" x2="100.273441%" y2="49.95997%" id="linearGradient-2">
                    <stop stopColor="#007940" offset="0%"/>
                    <stop stopColor="#00873F" offset="22.85%"/>
                    <stop stopColor="#40A737" offset="74.33%"/>
                    <stop stopColor="#5CB531" offset="100%"/>
                </linearGradient>
                <linearGradient x1="-62.8015845%" y1="49.8578253%" x2="253.671294%" y2="49.8578253%" id="linearGradient-3">
                    <stop stopColor="#007940" offset="0%"/>
                    <stop stopColor="#00873F" offset="22.85%"/>
                    <stop stopColor="#40A737" offset="74.33%"/>
                    <stop stopColor="#5CB531" offset="100%"/>
                </linearGradient>
                <linearGradient x1="0.175556793%" y1="50.0058048%" x2="101.808162%" y2="50.0058048%" id="linearGradient-4">
                    <stop stopColor="#1F286F" offset="0%"/>
                    <stop stopColor="#004E94" offset="47.51%"/>
                    <stop stopColor="#0066B1" offset="82.61%"/>
                    <stop stopColor="#006FBC" offset="100%"/>
                </linearGradient>
                <linearGradient x1="-0.575855512%" y1="49.9142191%" x2="98.13299%" y2="49.9142191%" id="linearGradient-5">
                    <stop stopColor="#6C2C2F" offset="0%"/>
                    <stop stopColor="#882730" offset="17.35%"/>
                    <stop stopColor="#BE1833" offset="57.31%"/>
                    <stop stopColor="#DC0436" offset="85.85%"/>
                    <stop stopColor="#E60039" offset="100%"/>
                </linearGradient>
            </defs>
            <g>
                <path d="M256.000001,157.418094 C256.000001,179.325184 238.16137,197.163815 216.254279,197.163815 L-3.43623867e-08,197.163815 L-3.43623867e-08,39.7457218 C-3.43623867e-08,17.8386312 17.8386309,3.69395977e-07 39.7457214,3.69395977e-07 L256.000001,3.69395977e-07 L256.000001,157.418094 Z" fill="#FFFFFF"/>
                <path d="M185.584353,117.046455 L202.01467,117.046455 C202.484108,117.046455 203.579463,116.889977 204.048901,116.889977 C207.178485,116.26406 209.838631,113.447434 209.838631,109.535453 C209.838631,105.779952 207.178485,102.963326 204.048901,102.18093 C203.579463,102.024451 202.640588,102.024451 202.01467,102.024451 L185.584353,102.024451 L185.584353,117.046455 Z" fill="url(#linearGradient-1)"/>
                <path d="M200.13692,13.3007339 C184.488999,13.3007339 171.657702,25.9755506 171.657702,41.7799516 L171.657702,71.354524 L211.872861,71.354524 C212.811737,71.354524 213.907092,71.354524 214.689488,71.511003 C223.765282,71.980441 230.493888,76.6748175 230.493888,84.8117365 C230.493888,91.2273845 225.955991,96.7041575 217.506113,97.799512 L217.506113,98.11247 C226.738387,98.738387 233.779952,103.902201 233.779952,111.882641 C233.779952,120.488998 225.955991,126.122251 215.628363,126.122251 L171.501223,126.122251 L171.501223,184.019561 L213.281174,184.019561 C228.929097,184.019561 241.760392,171.344744 241.760392,155.540344 L241.760392,13.3007339 L200.13692,13.3007339 L200.13692,13.3007339 Z" fill="url(#linearGradient-2)"/>
                <path d="M207.804402,86.6894875 C207.804402,82.933986 205.144255,80.4303185 202.01467,79.960881 C201.701713,79.960881 200.919316,79.8044015 200.449879,79.8044015 L185.584353,79.8044015 L185.584353,93.574573 L200.449879,93.574573 C200.919316,93.574573 201.858192,93.574573 202.01467,93.4180935 C205.144255,92.948656 207.804402,90.4449885 207.804402,86.6894875 L207.804402,86.6894875 Z" fill="url(#linearGradient-3)"/>
                <path d="M42.7188266,13.3007339 C27.0709047,13.3007339 14.2396088,25.9755506 14.2396088,41.7799516 L14.2396088,112.03912 C22.2200489,115.951101 30.5134475,118.454768 38.8068461,118.454768 C48.6650368,118.454768 53.9853305,112.508558 53.9853305,104.371639 L53.9853305,71.1980445 L78.3960885,71.1980445 L78.3960885,104.215159 C78.3960885,117.046455 70.415648,127.530563 43.3447434,127.530563 C26.9144255,127.530563 14.0831296,123.931541 14.0831296,123.931541 L14.0831296,183.863082 L55.863081,183.863082 C71.5110025,183.863082 84.3422985,171.188265 84.3422985,155.383864 L84.3422985,13.3007339 L42.7188266,13.3007339 L42.7188266,13.3007339 Z" fill="url(#linearGradient-4)"/>
                <path d="M121.427874,13.3007339 C105.779951,13.3007339 92.9486555,25.9755506 92.9486555,41.7799517 L92.9486555,79.0220055 C100.146699,72.919316 112.665037,69.0073355 132.850856,69.946211 C143.647922,70.4156485 155.227384,73.3887535 155.227384,73.3887535 L155.227384,85.4376535 C149.437654,82.4645485 142.552568,79.8044015 133.633252,79.178485 C118.298289,78.0831305 109.066015,85.594133 109.066015,98.738387 C109.066015,112.03912 118.298289,119.550123 133.633252,118.298289 C142.552568,117.672372 149.437654,114.855746 155.227384,112.03912 L155.227384,124.08802 C155.227384,124.08802 143.804402,127.061126 132.850856,127.530563 C112.665037,128.469438 100.146699,124.557458 92.9486555,118.454768 L92.9486555,184.17604 L134.728607,184.17604 C150.376529,184.17604 163.207824,171.501223 163.207824,155.696822 L163.207824,13.3007339 L121.427874,13.3007339 L121.427874,13.3007339 Z" fill="url(#linearGradient-5)"/>
            </g>
		</SvgIcon>
	);
}

export default JCBIcon;
