import { call, fork, put, takeLatest } from 'redux-saga/effects';
import download from 'downloadjs';

import { api } from 'api';

import { enqueue } from 'store/reducers/notifications';

import {
	actions,
	downloadPending,
	downloadFulfilled,
	downloadError,
} from './reducer';

function* handleDownloadShift({ payload: shiftId }) {
	console.log(shiftId)
	yield put(downloadPending());
	try {
		const data = yield call(api.downloadShift, shiftId);
		download(data, `shift_${shiftId}.pdf`, 'application/pdf');
		yield put(downloadFulfilled());
	} catch(error) {
		yield put(enqueue({ message: `An error occurred downloading the shift: ${error.message}`, options: { variant: 'error' } }));
		yield put(downloadError(error));
		throw error;
	}
}

export const suitParamsToExport = ({
  period: { startDate, endDate },
  ...filters
}) => {
  const normalizeArray = (param) => {
    let newParam = param?.length > 0 ? param : null;
    if (newParam) {
      newParam = Array.isArray(param) ? param : [param];
    }
    return newParam;
  }

  return {
		storeId: normalizeArray(filters.units),
    shiftDateFrom: startDate,
    shiftDateTo: endDate,
		shiftCode: filters.query,
		outputType: filters.exportType,
  };
	
};

function* downloadShift() {
	yield takeLatest(actions.DOWNLOAD, handleDownloadShift);
}

export function* shiftsSaga() {
	yield fork(downloadShift);
}