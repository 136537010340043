import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: 20,
    paddingBottom: 20,
    color: theme.palette.text.disabled,
    "&:hover > *": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.text.primary
          : theme.palette.primary.main,
    },
    "&:hover::after": {
      opacity: 1,
    },
    "&::after": {
      //@include animate(opacity);
      borderRradius: 4,
      content: '""',
      opacity: 0,
      position: "absolute",
      height: 32,
      top: "50%",
      transform: "translateY(-50%)",
      right: 0,
      width: 3,
      display: "block",
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.text.primary
          : theme.palette.primary.main,
    },
  },
  listItemSelected: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.text.primary
        : theme.palette.primary.main,
    backgroundColor: "transparent !important",
    "& > *": {
      color: `${
        theme.palette.type === "dark"
          ? theme.palette.text.primary
          : theme.palette.primary.main
      } !important`,
    },
    "&::after": {
      opacity: 1,
    },
  },
  listItemGutters: {
    paddingLeft: 30,
    paddingRight: 30,
    transition: theme.transitions.create("padding-left", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up("md")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },

    "& + div > div > div > div:not(.MuiCollapse-wrapperInner)": {
      paddingLeft: 40,
    },
  },
  listItemGuttersClosed: {
    paddingLeft: 25,
  },
  listItemButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  itemText: {
    marginTop: 0,
    marginBottom: 0,
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  itemTextPrimary: {
    fontSize: theme.typography.body2.fontSize,
  },
  itemTextClosed: {
    opacity: 0,
  },
  itemIcon: {
    minWidth: 36,
    color: theme.palette.text.disabled,
  },
}));
