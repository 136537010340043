import { fork, takeLatest } from "redux-saga/effects";

import { transactionsSaga } from "features/transactions/saga";
import { sendPayByLinkSaga } from "features/paybylink/saga";
import { dashboardSaga } from "features/dashboard/saga";
import { reportSaga } from "features/common/report/saga";
import { statisticsSaga } from "features/statistics/saga";
import { administrationSaga } from "features/administration/saga";
import { keysSaga } from "features/developers/ApiKeys/saga";
import { ordersSaga } from "features/orders/saga";
import { shiftsSaga } from "features/shifts/saga";
import { exportSaga } from "./export";
import { reverseSaga } from "./reverse";
import { chargebackSaga } from "features/chargebacks/saga";
import { webhooksSaga } from "features/developers/Webhooks/saga";
import { storesSaga } from "features/settings/Store/saga";
import { payoutsSaga } from "features/administration/Payouts/saga";

import userSaga from "./user";
import { setAppReady } from "store/reducers/user";

function* handleAppReady() {
  yield fork(transactionsSaga);
  yield fork(exportSaga);
  yield fork(reverseSaga);
  yield fork(reportSaga);
  yield fork(sendPayByLinkSaga);
  yield fork(statisticsSaga);
  yield fork(dashboardSaga);
  yield fork(administrationSaga);
  yield fork(shiftsSaga);
  yield fork(keysSaga);
  yield fork(ordersSaga);
  yield fork(chargebackSaga);
  yield fork(webhooksSaga);
  yield fork(storesSaga);
  yield fork(payoutsSaga);
}

function* appSaga() {
  yield takeLatest(setAppReady.type, handleAppReady);
}

export default function* rootSaga() {
  yield fork(userSaga);
  yield fork(appSaga);
}
