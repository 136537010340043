import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function UserIcon(props) {
	return (
		<SvgIcon {...props}>
			<g>
				<rect width="24" height="24" fill="none"/>
				<path d="M17.75977,12.21338,15.72333,11.1488c.0166-.03326.03595-.06506.05206-.09851a3.9665,3.9665,0,0,0,1.23633-2.09912,2.7572,2.7572,0,0,0-.083-2.37988,1.43787,1.43787,0,0,0-.14941-.17481,14.195,14.195,0,0,0-.0918-2.17822A4.67585,4.67585,0,0,0,11.957.60107,4.68388,4.68388,0,0,0,7.15918,4.2998,14.67111,14.67111,0,0,0,7.0791,6.35889a1.345,1.345,0,0,0-.16308.17724,2.85806,2.85806,0,0,0-.1084,2.415,3.89334,3.89334,0,0,0,1.30273,2.15235c.01569.03173.03955.07037.05615.10284L6.24023,12.21289a7.876,7.876,0,0,0-4.24121,7.001v1.81152a.89991.89991,0,0,0,.625.85645A33.20305,33.20305,0,0,0,12,23.00586a33.20305,33.20305,0,0,0,9.376-1.124.89991.89991,0,0,0,.625-.85645V19.21387A7.8747,7.8747,0,0,0,17.75977,12.21338ZM8.6582,7.53613a.90449.90449,0,0,0,.24512-.67724A15.04416,15.04416,0,0,1,8.93164,4.604a2.91208,2.91208,0,0,1,3.02051-2.20361,2.89667,2.89667,0,0,1,2.96387,2.124,14.06275,14.06275,0,0,1,.04,2.33545.89757.89757,0,0,0,.46973.84864,3.79765,3.79765,0,0,1-.14844.75879A2.12974,2.12974,0,0,1,14.582,9.69385a.89381.89381,0,0,0-.34668.39795c-.08105.1831-.16992.36377-.23047.47412-.33984.48535-1.293,1.4873-2.07519,1.4873-.83594,0-1.80274-1.10254-2.03516-1.42578-.09668-.16455-.17969-.33545-.25976-.50879a.897.897,0,0,0-.375-.40967c-.165-.09326-.47754-.3789-.71778-1.24267a3.85352,3.85352,0,0,1-.14746-.73877A.90343.90343,0,0,0,8.6582,7.53613Zm11.542,12.80078A35.383,35.383,0,0,1,12,21.20508a35.383,35.383,0,0,1-8.2002-.86817v-1.123a6.08182,6.08182,0,0,1,3.27442-5.40528l2.23144-1.167.004-.00323a4.00942,4.00942,0,0,0,2.62,1.21515,4.05386,4.05386,0,0,0,2.68255-1.27839.83621.83621,0,0,0,.0821.06647l2.23144,1.167a6.08182,6.08182,0,0,1,3.27442,5.40528Z" fill="#83858f"/>
			</g>
		</SvgIcon>
	);
}

export default UserIcon;