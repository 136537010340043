import { call, fork, put, takeLatest } from "redux-saga/effects";
import { takeEveryAsync, takeLatestAsync } from "saga-toolkit";
import download from "downloadjs";
import { formatISO, parseISO } from "date-fns";

import { api } from "api";

import { REFUND_STATUSES, CAPTURE_STATUSES, TRANSACTION_STATUSES, DEFAULT_PAGE_SIZE } from "services/config";
import { enqueue } from "store/reducers/notifications";

import {
  fetchTransactions,
  transactionRefund,
  transactionCapture,
  updateTransaction,
  actions,
  downloadPending,
  downloadFulfilled,
  downloadError,
} from "./reducer";

export const getParamsFromFilters = ({
  query,
  period: { startDate, endDate } = {},
  statuses,
  channel,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  journalType,
  amount,
  allCurrenciesSelected = false,
  ...filters
}) => {
  let newStatuses = statuses;
  if (statuses && statuses.includes(TRANSACTION_STATUSES.PROCESSING)) {
    if (!statuses.includes(TRANSACTION_STATUSES.ACQUIRERPROCESSING)) {
      newStatuses = newStatuses.concat([TRANSACTION_STATUSES.ACQUIRERPROCESSING]);
    }

    if (!statuses.includes(TRANSACTION_STATUSES.ACQUIRERTEMPORARYFAILED)) {
      newStatuses = newStatuses.concat([TRANSACTION_STATUSES.ACQUIRERTEMPORARYFAILED]);
    }
  }

  if (statuses && !statuses.includes(TRANSACTION_STATUSES.PROCESSING)) {
    newStatuses = newStatuses.filter(
      (x) => x !== TRANSACTION_STATUSES.ACQUIRERPROCESSING && x !== TRANSACTION_STATUSES.ACQUIRERTEMPORARYFAILED
    );
  }

  return {
    AmountFrom: amount?.min,
    AmountTo: amount?.max,
    GroupId: filters.groupId ? Object.keys(filters.groupId) : null,
    StoreId: filters.storeId,
    MerchantId: filters.merchantId ? Object.keys(filters.merchantId) : null,
    PageSize: pageSize,
    AcquirerAuthorizationCode: filters.aquirerAuthorizationCode,
    ApplicationId: filters.applicationId,
    CardHolderName: filters.cardHolderName,
    CardNumber: filters.cardNumber,
    ClientFrequentFlightNumber: filters.clientFrequentFlyerNumber,
    Country: filters.country,
    Iban: filters.iban,
    IsOneClickPayment: filters.isOneClickPayment,
    LastFourCardNumber: filters.lastFourCardNumber,
    OrderRef: filters.orderRef,
    Page: pageNumber,
    PhoneNumber: filters.phoneNumber ? `${filters.phoneNumber?.countryCode}${filters.phoneNumber?.number}` : null,
    Pnr: filters.pnr,
    ShopperEmail: filters.shopperEmail,
    ShopperIp: filters.shopperIp,
    TransactionDateFrom: startDate,
    TransactionDateTo: endDate,
    Id: filters.transactionId,
    ShiftId: filters.shiftId,
    OrderId: filters.orderId,
    PaymentMethod: filters.paymentMethod ? Object.keys(filters.paymentMethod) : null,
    Currency: !allCurrenciesSelected && filters.currency.filter((x) => x).length > 0 ? filters.currency.join(',') : null,
    TransactionMode: channel,
    TransactionState: newStatuses,
    TransactionType: journalType,
    IsProduction: filters.isProduction,
    CardScheme: filters.cardScheme ? Object.keys(filters.cardScheme) : null,
    Acquirer: filters.acquirer ? Object.keys(filters.acquirer) : null,
    Refetch: filters.refetch,
    outputType: filters.exportType,
  };
};

export const suitParamsToExport = (params) => {
  
  const normalizedParams = getParamsFromFilters(params);

  const normalizeArray = (param) => {
    let newParam = param?.length > 0 ? param : null;
    if (newParam) {
      newParam = Array.isArray(param) ? param : [param];
    } 
    return newParam;
  }

  return {
    ...normalizedParams,
    Currency: normalizedParams.Currency?.split(','),
    ApplicationId: normalizeArray(normalizedParams.ApplicationId),
    Acquirer: normalizeArray(normalizedParams.Acquirer),
    CardScheme: normalizeArray(normalizedParams.CardScheme),
    Country: normalizeArray(normalizedParams.Country),
    GroupId: normalizeArray(normalizedParams.GroupId),
    MerchantId: normalizeArray(normalizedParams.MerchantId),
    StoreId: normalizeArray(normalizedParams.StoreId),
    PaymentMethod: normalizeArray(normalizedParams.PaymentMethod),
    TransactionMode: normalizeArray(normalizedParams.TransactionMode),
    TransactionState: normalizeArray(normalizedParams.TransactionState),
    TransactionType: normalizeArray(normalizedParams.TransactionType),
  }

};

function* handleFetch({ meta: { arg } }) {
  try {
    const data = yield call(api.getTransactions, getParamsFromFilters(arg));

    if (data.code || data.error) {
      throw new Error(data.description || data.error?.internalMessage);
    }

    return data;
  } catch (error) {
    yield put(enqueue({ message: error.message, options: { variant: "error" } }));
    throw error;
  }
}

function* handleRefund({ meta: { arg } }) {
  const { paymentId, amount, merchantId } = arg;
  try {
    const data = yield call(api.refund, paymentId, amount, merchantId);
    const { code, description } = data;

    if (code === "BYRD200") {
      const {
        sourceTransaction: { refundedAmount, amount },
      } = data;
      const isTotal = parseFloat(refundedAmount) === parseFloat(amount);
      const allowedOperations = isTotal ? [] : ["refund"];
      const relatedOperationStatus = isTotal ? REFUND_STATUSES.REFUNDED : REFUND_STATUSES.PARTIALLY_REFUNDED;

      // Uppon success update the transaction data locally
      yield put(
        updateTransaction({
          paymentId,
          relatedOperationStatus,
          allowedOperations,
        })
      );
      // Notify success
      yield put(
        enqueue({
          message: `Transaction ${paymentId} was refunded successfully!`,
        })
      );

      return true;
    }

    throw new Error(description);
  } catch (error) {
    // Notify error
    yield put(
      enqueue({
        message: `An error occurred refunding transaction ${paymentId}: ${error.message}`,
        options: { variant: "error" },
      })
    );
    throw error;
  }
}

function* handleCapture({ meta: { arg } }) {
  const { paymentId, amount, merchantId } = arg;
  try {
    const data = yield call(api.capture, paymentId, amount, merchantId);
    const { code, description } = data;

    if (code === "BYRD200") {
      const {
        sourceTransaction: { capturedAmount, amount },
      } = data;
      const isTotal = parseFloat(capturedAmount) === parseFloat(amount);
      const allowedOperations = isTotal ? [] : ["capture"];
      const relatedOperationStatus = isTotal ? CAPTURE_STATUSES.CAPTURED : CAPTURE_STATUSES.PARTIALLY_CAPTURED;

      // Uppon success update the transaction data locally
      yield put(
        updateTransaction({
          paymentId,
          relatedOperationStatus,
          allowedOperations,
        })
      );
      // Notify success
      yield put(
        enqueue({
          message: `Transaction ${paymentId} was captured successfully!`,
        })
      );

      return data;
    }

    throw new Error(description);
  } catch (error) {
    // Notify error
    yield put(
      enqueue({
        message: `An error occurred capturing transaction ${paymentId}: ${error.message}`,
        options: { variant: "error" },
      })
    );
    throw error;
  }
}

function* handleDownloadTransaction({ payload: transactionId }) {
  yield put(downloadPending());
  try {
    const data = yield call(api.downloadTransaction, transactionId);
    download(data, `transaction_${transactionId}.pdf`, "application/pdf");
    yield put(downloadFulfilled());
  } catch (error) {
    yield put(
      enqueue({
        message: `An error occurred downloading the transaction: ${error.message}`,
        options: { variant: "error" },
      })
    );
    yield put(downloadError(error));
    throw error;
  }
}

function* downloadTransaction() {
  yield takeLatest(actions.DOWNLOAD, handleDownloadTransaction);
}

function* fetchSaga() {
  yield takeLatestAsync(fetchTransactions.type, handleFetch);
}

function* refundSaga() {
  yield takeEveryAsync(transactionRefund.type, handleRefund);
}

function* captureSaga() {
  yield takeEveryAsync(transactionCapture.type, handleCapture);
}

export function* transactionsSaga() {
  yield fork(fetchSaga);
  yield fork(refundSaga);
  yield fork(captureSaga);
  yield fork(downloadTransaction);
}
