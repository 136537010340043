import React, { useMemo, useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import DateFnsUtils from '@date-io/date-fns';
import { init as initApm } from '@elastic/apm-rum';
import { IntlProvider } from '@wikimedia/react.i18n';
import useHotjar from 'react-use-hotjar';
import TagManager from 'react-gtm-module'

import { useAuth } from 'common/auth/Auth0ProviderWithHistory';
import { makeTheme } from './styles/theme';
import { Root } from './root';
import messages from './i18n';

import Loading from 'common/layout/Loading';

import { NODE_ENV } from 'services/config';
import { storeLocale, refreshTimeZoneSpecs } from 'services/utils';

// Configure elastic search API
initApm({
	serviceName: 'paybyrd-dashboard',
	serverUrl: 'https://17eaa08527544fc8a5195f47a30ead3f.apm.westeurope.azure.elastic-cloud.com:443',
	environment: process.env.NODE_ENV,
});

export function App() {
	const { mode, locale } = useSelector(state => state.ui);
	const theme = useMemo(() => makeTheme(mode, locale), [mode, locale]);
	const { initHotjar } = useHotjar();
	const { isLoading } = useAuth();
	const isDev = NODE_ENV === 'development';
	// TODO - Is it possible to receive this the same as API_URL?
	const isStage = window.location.href.includes('dashboard-s');
	
	useEffect(() => {
		// Google Tag Manager just for Prod
		if (!isStage && !isDev) {
			TagManager.initialize({ gtmId: 'GTM-K7MRC7M' });
		}
		refreshTimeZoneSpecs();
	}, []);

	useEffect(() => {
		if (isStage || isDev) {
			initHotjar(2699757, 6);
		} else {
			// Production
			initHotjar(2739668, 6);
		}

	}, [initHotjar]);

	if(isLoading) {
		return <Loading />;
	}
	

	storeLocale(locale);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<ThemeProvider theme={theme}>
  				<IntlProvider locale={locale} messages={messages}>
					<SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
						<ConfirmProvider defaultOptions={{ confirmationButtonProps: { autoFocus: true } }}>
							<CssBaseline />
							<Root />
						</ConfirmProvider>
					</SnackbarProvider>
				</IntlProvider>
			</ThemeProvider>
		</MuiPickersUtilsProvider>
	)
}

export default App;