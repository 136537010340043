import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	definitions: {
		marginBottom: 20
	},
	definition: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '20px 0',
		borderBottom: `1px solid ${theme.palette.mediumGray2.main}`,
		fontSize: 16,
		'&:last-of-type': {
			borderBottom: 'none',
		}
	},
	title: {
		textTransform: 'uppercase',
		color: theme.palette.mediumGray.main,
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'right',
	},
}));