import React, { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";

import FormInput from "common/ui/FormInput";
import { useStyles } from "./styles";

export const FormTextInput = (
  {
    value = null,
    error,
    label,
    onChange,
    name = "",
    inputComponent = null,
    labelAction = null,
    multiline,
    type = "text",
    autoFocus,
    max,
    precision = 2,
    ...props
  },
  ref
) => {
  const classes = useStyles();

  return (
    <FormInput label={label} labelAction={labelAction} {...props}>
      <TextField
        ref={ref}
        placeholder={props.placeholder || ""}
        autoFocus={autoFocus}
        type={type}
        error={!!error}
        fullWidth
        multiline={multiline}
        rows={multiline && 5}
        InputProps={
          inputComponent
            ? {
                inputComponent,
                disableUnderline: true,
                classes: {
                  input: classes.input,
                  error: classes.error,
                },
                name,
                value,
                precision,
              }
            : {
                disableUnderline: true,
                classes: {
                  input: classes.input,
                  error: classes.error,
                },
              }
        }
        value={value || ""}
        onChange={(e) => {
          if (max && e.target.value.length > max) {
            return;
          }

          return onChange(e);
        }}
      />
      {!!error && <div className={classes.errorField}>{error}</div>}
    </FormInput>
  );
};

export default forwardRef(FormTextInput);
