import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function StatisticsIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M16.255 15.6c-1.745 0-3.164-1.418-3.164-3.164s1.418-3.164 3.164-3.164 3.164 1.418 3.164 3.164c0 1.745-1.418 3.164-3.164 3.164zM16.255 11.236c-0.655 0-1.2 0.545-1.2 1.2s0.545 1.2 1.2 1.2 1.2-0.545 1.2-1.2-0.545-1.2-1.2-1.2zM6.436 11.236c-1.745 0-3.164-1.418-3.164-3.164s1.418-3.164 3.164-3.164 3.164 1.418 3.164 3.164-1.418 3.164-3.164 3.164zM6.436 6.873c-0.655 0-1.2 0.545-1.2 1.2s0.545 1.2 1.2 1.2 1.2-0.545 1.2-1.2-0.545-1.2-1.2-1.2zM0.982 21.055c-0.109 0-0.218 0-0.436-0.109-0.545-0.218-0.764-0.764-0.545-1.309l4.364-9.818c0.218-0.545 0.764-0.764 1.309-0.545s0.764 0.764 0.545 1.309l-4.364 9.818c-0.109 0.436-0.545 0.655-0.873 0.655zM17.891 11.236c-0.218 0-0.436-0.109-0.545-0.218-0.436-0.327-0.545-0.982-0.218-1.418l4.909-6.545c0.327-0.327 0.873-0.436 1.309-0.109s0.545 0.982 0.218 1.418l-4.909 6.545c-0.218 0.218-0.436 0.327-0.764 0.327zM14.073 12.327c-0.109 0-0.218 0-0.327-0.109l-5.455-2.182c-0.545-0.109-0.764-0.764-0.545-1.2 0.218-0.545 0.764-0.764 1.309-0.545l5.455 2.182c0.545 0.218 0.764 0.764 0.545 1.309-0.218 0.327-0.545 0.545-0.982 0.545z"></path>
		</SvgIcon>
	);
}

export default StatisticsIcon;