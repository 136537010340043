import React from 'react';
import clsx from 'clsx';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useStyles } from './style';

export function MenuItem({ label, icon, open, onClick, selected, slug, ...props }) {
	const classes = useStyles();

	return (
		<ListItem {...props} selected={selected} button classes={{
			root: classes.listItem,
			gutters: clsx(classes.listItemGutters, {
				[classes.listItemGuttersClosed]: !open
			}),
			button: classes.listItemButton,
			selected: classes.listItemSelected
		}} onClick={() => onClick && onClick(slug)}>
			<ListItemIcon classes={{ root: classes.itemIcon }}>{icon}</ListItemIcon>
			<ListItemText primary={label} classes={{
				root: clsx(classes.itemText, {
					[classes.itemTextClosed]: !open
				}),
				primary: classes.itemTextPrimary
			}} />
		</ListItem>
	);
}

export default MenuItem;