import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.background.default,
    padding: "35px 32px",
    maxWidth: 1000,
    borderRadius: 4,
    maxHeight: "90vh",
    overflow: "auto",
  },
}));
