import React from 'react';
import { useAuth } from "common/auth/Auth0ProviderWithHistory";
import { Redirect } from 'react-router-dom';

import Login from 'common/login';

export const Home = () => {
	const { user } = useAuth();

	if(user) {
		return <Redirect to="/dashboard" />
	}

	return <Login />;
}

export default Home;