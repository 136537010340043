import { call, fork } from "redux-saga/effects";
import { takeLatestAsync } from "saga-toolkit";
import { api } from "api";
import {
	fetchPayouts,
	fetchPayout,
} from "./reducer";
import { DEFAULT_PAGE_SIZE } from "services/config";

export const getParamsFromFilters = ({
  period: { startDate, endDate } = {},
  pageSize = DEFAULT_PAGE_SIZE,
  page = 1,
}) => {
  return {
    PaidFrom: startDate,
    PaidTo: endDate,
    Page: page,
    PageSize: pageSize
  };
};

function* handleFetchPayouts({ meta: { arg } }) {
	try {
		const data = yield call(api.getPayouts, getParamsFromFilters(arg));
		return data.value ? data.value : data;
	} catch (error) {
		throw new Error(error.message);
	}
}

function* handleFetchPayout({ meta: { arg } }) {
	try {
		const data = yield call(api.getPayout, arg);
		return data.value ? data.value : data;
	} catch (error) {
		throw new Error(error.message);
	}
}

function* fetchPayoutsSaga() {
	yield takeLatestAsync(fetchPayouts.type, handleFetchPayouts);
}

function* fetchPayoutSaga() {
	yield takeLatestAsync(fetchPayout.type, handleFetchPayout);
}

export function* payoutsSaga() {
	yield fork(fetchPayoutsSaga);
	yield fork(fetchPayoutSaga);
}
