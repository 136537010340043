import Cookies from "js-cookie";


export function mapQuartersToMonths(month) {
  const firstIndex = [1, 4, 7, 10];
  const secondIndex = [2, 5, 8, 11];

  if (firstIndex.includes(month)) {
    return 0;
  }

  if (secondIndex.includes(month)) {
    return 1;
  }

  return 2;
}

export function getQuarterReference(month) {
  const quarters = {
    1: 1,
    2: 1,
    3: 1,
    4: 2,
    5: 2,
    6: 2,
    7: 3,
    8: 3,
    9: 3,
    10: 4,
    11: 4,
    12: 4,
  };

  return quarters[month];
}


export function currencyFormat(
  amount = 0,
  locale = "pt-PT",
  currency = Cookies.get("defaultCurrency")
) {
  if (isNaN(amount)) {
    return new Intl.NumberFormat(locale, { currency }).format(0);
  }

  return new Intl.NumberFormat(locale, {
    currency,
    minimumFractionDigits: 2,
  }).format(amount);
}

export function reducePeriod(data) {
  return data.reduce((period, month) => {
    for (const [propName, propValue] of Object.entries(month)) {
      if (!period[propName]) {
        period[propName] = 0;
      }

      if (!isNaN(propValue)) {
        period[propName] += propValue;
      } else {
        period[propName] = propValue;
      }
    }

    return period;
  }, {});
}

function countChartItems(amount) {
  const array = [];

  for (let i = 0; i < amount; i++) {
    array.push({
      name: i + 1,
      prevAmount: 0,
      currentAmount: 0,
    });
  }

  return array;
}

export const getChartInitialState = {
  daily: () => countChartItems(24),
  weekly: () => countChartItems(7),
  monthly: () => countChartItems(31),
  quarterly: () => countChartItems(3),
  yearly: () => countChartItems(12),
};

export function getDateFromString(date) {
  return new Date(date);
}
