import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function MulticaixaIcon(props) {
	return (
		<SvgIcon {...props} viewBox="250 50 30 560" style={{ width:37, height:37 }}>
                  <path fill="#F09134" opacity="1.000000" stroke="none" d="M373.559448,123.328979 
                        C379.121368,123.831627 384.683960,123.509445 389.316040,125.430634 
                        C396.328766,128.339172 398.841766,135.117065 398.940308,142.381516 
                        C399.143005,157.324265 399.001251,172.271683 399.001251,186.250916 
                        C400.312042,187.437637 400.580597,187.872955 400.795532,187.847916 
                        C414.015320,186.307922 423.832581,194.343933 434.438690,199.958374 
                        C442.969360,204.474197 451.307648,209.376083 459.563019,214.384033 
                        C472.799866,222.413925 474.727936,235.024582 464.583252,246.825699 
                        C455.323700,257.597107 445.867493,268.199585 436.629547,278.989197 
                        C435.808929,279.947601 435.232269,282.528015 435.769592,283.023132 
                        C443.331940,289.991364 444.432739,299.745941 446.865601,308.785797 
                        C450.087555,320.757507 452.574890,332.926605 455.393860,345.007172 
                        C457.721588,354.982452 450.790741,365.940125 440.696533,366.881622 
                        C425.245575,368.322723 409.724640,369.021027 394.230011,369.979736 
                        C391.746704,370.133392 389.245697,370.001343 387.254181,370.001343 
                        C385.799622,376.241028 385.670349,382.911987 382.787140,388.032166 
                        C373.647736,404.262512 364.002594,420.271912 353.477966,435.630066 
                        C348.591400,442.760834 337.790924,443.232117 330.723145,438.044373 
                        C323.434418,432.694489 316.420197,426.928162 309.631897,420.951141 
                        C303.045502,415.151886 296.898468,408.853668 290.335297,402.560516 
                        C277.198029,416.750092 258.624115,415.831360 242.319702,420.902008 
                        C237.058655,422.538208 231.292160,422.539886 226.019073,424.148712 
                        C214.908279,427.538605 202.724686,416.644165 202.799866,406.346924 
                        C202.906128,391.798248 201.669220,377.239502 200.989868,362.685822 
                        C200.924194,361.278839 200.741592,359.877350 200.613998,358.481384 
                        C192.295044,359.143158 185.339661,357.151978 178.758545,352.991211 
                        C166.432953,345.198547 154.005997,337.555573 141.446075,330.147247 
                        C128.753693,322.660828 127.808090,308.297882 137.343613,298.038849 
                        C147.696121,286.900818 157.568817,275.316803 167.900742,263.644836 
                        C157.482086,253.738342 157.450027,239.155716 153.127563,226.305756 
                        C150.797653,219.379318 149.770569,212.025055 147.911362,204.927795 
                        C143.663498,188.712082 152.811203,178.805191 168.711761,177.946762 
                        C184.349319,177.102509 199.963745,175.829941 215.943909,174.720612 
                        C215.308594,162.079041 223.275879,152.937256 228.826981,142.881531 
                        C233.727829,134.003723 239.425934,125.569717 244.616776,116.847366 
                        C251.143173,105.880821 265.027191,103.689331 274.653839,112.137756 
                        C286.579376,122.603706 298.580170,132.983887 310.955872,143.755753 
                        C320.689484,131.219986 336.297333,131.877380 350.093719,128.120987 
                        C357.636444,126.067299 365.451355,125.013344 373.559448,123.328979 
                  M262.819427,240.308380 
                        C261.840576,241.444214 260.860016,242.578629 259.883087,243.716110 
                        C245.920731,259.972870 245.108719,286.539642 258.053009,303.574066 
                        C281.956360,335.030396 328.165863,330.969971 346.152344,295.832794 
                        C359.125183,270.489960 344.535980,236.327301 317.354980,227.809113 
                        C296.915619,221.403687 278.985809,225.345566 262.819427,240.308380 
                  M246.993835,232.437180 
                        C248.078568,231.229538 249.163300,230.021896 250.186234,228.883041 
                        C246.107407,223.317856 241.532639,220.698227 234.501709,220.905258 
                        C215.878357,221.453629 197.227783,221.071442 178.588043,221.080612 
                        C176.838562,221.081482 175.089127,221.231659 173.636398,221.298813 
                        C173.309128,222.068970 173.162628,222.239227 173.190674,222.372849 
                        C174.445740,228.352219 175.802078,234.311691 176.964920,240.308731 
                        C178.780609,249.672699 184.790268,255.027222 194.258209,254.931870 
                        C206.711563,254.806442 219.166107,254.441101 231.606354,253.875900 
                        C232.995148,253.812805 234.661758,252.017456 235.571045,250.617294 
                        C239.339859,244.813904 242.867264,238.853760 246.993835,232.437180 
                  M415.016541,213.495453 
                        C414.432953,213.177841 413.830200,212.890701 413.268585,212.538132 
                        C402.692657,205.898987 396.578949,206.413635 388.206879,215.401337 
                        C380.070831,224.135681 372.245636,233.164963 364.491028,242.241669 
                        C363.630768,243.248596 363.641541,245.559494 364.079193,247.016006 
                        C365.741638,252.548843 368.029022,257.903625 369.512817,263.476715 
                        C370.371979,266.703583 370.142944,270.220184 370.403809,273.608887 
                        C376.317841,273.178436 381.295074,273.026520 385.511017,268.138947 
                        C396.561005,255.328705 408.318390,243.129700 419.747986,230.645081 
                        C422.498444,227.640701 425.070770,224.473251 428.081665,220.965195 
                        C423.667664,218.436356 419.665405,216.143448 415.016541,213.495453 
                  M272.050934,213.532806 
                        C279.499847,210.059372 287.171204,207.620316 295.548737,207.836563 
                        C296.930664,207.872238 299.603363,206.525970 299.546204,206.115494 
                        C299.017365,202.318619 299.229675,197.444138 296.951202,195.032394 
                        C286.563202,184.036636 275.479523,173.694031 264.557587,163.208618 
                        C259.454712,158.309662 254.151520,153.619354 248.489899,148.422104 
                        C244.688995,154.803223 241.033997,160.534332 237.787582,166.488235 
                        C233.353409,174.620453 234.305420,180.800034 240.867355,187.238632 
                        C247.157181,193.410248 253.511063,199.529282 260.083313,205.395233 
                        C263.571869,208.508881 267.583710,211.036255 272.050934,213.532806 
                  M302.099152,344.465118 
                        C302.010590,350.749054 305.119965,355.161285 309.701538,359.255951 
                        C320.366302,368.787445 330.641510,378.753754 341.130371,388.483704 
                        C345.356934,392.404449 349.729462,396.167877 354.549072,400.460236 
                        C358.374512,393.700775 362.108551,387.455994 365.490936,381.026215 
                        C368.824524,374.689240 367.489197,367.129425 362.240875,362.279480 
                        C353.203613,353.928223 344.005951,345.750763 334.917114,337.454895 
                        C332.730286,335.458893 330.855042,335.345123 327.834320,336.353271 
                        C320.672791,338.743286 313.187439,340.155823 305.853210,342.042084 
                        C304.570679,342.371918 303.386749,343.085022 302.099152,344.465118 
                  M191.490433,303.990479 
                        C184.901291,310.523010 178.312134,317.055542 170.710144,324.592255 
                        C179.191284,329.375641 186.484192,334.289429 194.431442,337.660767 
                        C198.954300,339.579437 204.410034,338.207397 208.270096,334.248322 
                        C217.440384,324.842804 226.558517,315.385223 235.575394,305.833221 
                        C236.437485,304.919952 237.046661,303.039398 236.745911,301.872711 
                        C234.415314,292.831116 231.832016,283.854706 229.196701,274.390472 
                        C222.266159,273.286774 222.107025,273.332001 216.690079,278.753967 
                        C208.454529,286.997162 200.223389,295.244751 191.490433,303.990479 
                  M327.704559,158.182846 
                        C326.120697,161.376770 323.225403,164.552353 323.180420,167.767792 
                        C322.987549,181.556961 323.581543,195.359711 324.075592,209.150818 
                        C324.114075,210.225006 325.377899,211.622314 326.438660,212.234283 
                        C334.274872,216.755142 342.204163,221.114700 350.652893,225.831345 
                        C355.934875,222.853012 358.012299,217.282242 356.822296,210.140030 
                        C356.333954,207.209244 356.122223,204.202850 356.101471,201.228622 
                        C355.995697,186.068405 355.993195,170.907440 355.955841,155.746750 
                        C355.951477,153.967590 355.955231,152.188400 355.955231,149.397568 
                        C346.135681,152.290573 336.532745,152.467163 327.704559,158.182846 
                  M384.500000,328.020599 
                        C398.875427,328.020599 413.250854,328.020599 428.255249,328.020599 
                        C426.229370,319.797943 424.782501,312.493256 422.579590,305.424194 
                        C420.720398,299.458160 416.414185,295.102448 409.912933,295.063507 
                        C396.469635,294.983002 383.023285,295.694061 369.575592,295.838409 
                        C366.371033,295.872803 365.622620,297.580261 364.409851,300.005310 
                        C361.608490,305.606964 358.737396,311.306610 354.961121,316.254028 
                        C350.298462,322.362793 349.828979,322.136658 356.036346,326.510193 
                        C357.395325,327.467682 359.347473,327.969574 361.039337,328.001678 
                        C368.523651,328.143677 376.012665,328.037781 384.500000,328.020599 
                  M269.856659,392.571259 
                        C275.126007,388.529541 277.412994,383.369690 277.124603,376.570496 
                        C276.702148,366.609192 276.681610,356.605042 277.092499,346.644012 
                        C277.331818,340.842407 275.946503,337.690979 270.035767,335.164276 
                        C263.060303,332.182465 257.036865,326.973480 250.323624,322.547363 
                        C246.550781,326.090546 243.639816,330.032745 243.813492,336.562439 
                        C244.317398,355.507721 243.950836,374.476135 243.926422,393.435486 
                        C243.924179,395.176422 243.926132,396.917358 243.926132,399.911133 
                        C253.074539,397.392822 261.143127,395.171722 269.856659,392.571259 
                  M379.962433,188.467987 
                        C379.648804,177.998901 379.014313,167.526382 379.126526,157.061874 
                        C379.211121,149.177597 371.179291,142.867569 364.567078,147.878174 
                        C364.567078,169.258957 364.567078,190.413406 364.567078,212.608414 
                        C367.777588,209.569839 369.850189,206.726654 372.638641,205.147461 
                        C379.008392,201.539993 381.112457,196.357880 379.962433,188.467987 
                  M220.075714,392.873840 
                        C220.880905,394.858459 221.365967,397.065247 222.557327,398.781860 
                        C225.104202,402.451508 229.208725,402.854553 232.839203,401.529297 
                        C234.159058,401.047485 234.865692,397.483032 234.961365,395.286255 
                        C235.452408,384.010925 235.731247,372.724274 235.928879,361.438873 
                        C236.067032,353.549957 235.955505,345.656677 235.955505,336.517944 
                        C231.260925,340.430878 227.703781,343.884644 223.656433,346.610596 
                        C220.558792,348.696899 219.931778,351.136993 219.978027,354.557526 
                        C220.146713,367.033661 220.049423,379.513336 220.075714,392.873840 
                  M398.500000,336.018005 
                        C387.793396,336.018005 377.086792,336.018005 365.288086,336.018005 
                        C369.841370,341.592102 373.368927,346.167694 377.226685,350.445496 
                        C378.147217,351.466278 380.176575,351.932037 381.684387,351.908661 
                        C395.148834,351.699860 408.611420,351.350861 422.072876,350.981598 
                        C426.175629,350.869049 428.969757,348.622009 430.463715,344.936737 
                        C432.750153,339.296661 430.494263,336.026306 424.486267,336.020050 
                        C416.157501,336.011383 407.828766,336.017883 398.500000,336.018005 
                  M269.959076,136.410248 
                        C268.240540,135.427795 266.630585,134.039948 264.781555,133.544617 
                        C260.515625,132.401871 257.504822,134.715347 255.068832,137.932312 
                        C252.474609,141.358261 255.520874,142.992493 257.413177,144.887161 
                        C268.096283,155.583694 278.772308,166.288132 289.553955,176.884872 
                        C292.507385,179.787674 295.815704,182.329376 299.442383,185.450974 
                        C300.017273,178.991943 300.649323,173.592651 300.877869,168.176331 
                        C300.938141,166.748337 300.127106,164.876953 299.079956,163.872360 
                        C289.627289,154.803818 280.032959,145.882904 269.959076,136.410248 
                  M419.815796,265.302917 
                        C425.389557,259.164154 431.096985,253.139496 436.478546,246.836624 
                        C439.016968,243.863632 441.457275,240.585663 442.997925,237.036087 
                        C445.083588,232.230774 441.410706,229.221222 438.035767,226.817749 
                        C434.686462,224.432526 433.351074,228.193375 431.760742,229.850006 
                        C419.920959,242.183151 408.221466,254.651398 396.532196,267.128052 
                        C395.016754,268.745575 393.811768,270.653931 392.213867,272.755157 
                        C397.201904,273.002625 401.570007,272.775421 405.749756,273.556396 
                        C409.627106,274.280853 412.517120,273.758789 414.961945,270.591492 
                        C416.274902,268.890594 417.915253,267.442474 419.815796,265.302917 
                  M302.866272,369.505249 
                        C302.568634,373.637604 302.104431,377.768799 302.075226,381.903076 
                        C302.066528,383.137421 303.004120,384.669220 303.968323,385.574738 
                        C313.540497,394.564178 323.141327,403.526428 332.913116,412.296906 
                        C334.680603,413.883301 337.160583,415.111755 339.489288,415.540466 
                        C343.542572,416.286682 346.837830,413.949738 348.296204,410.509003 
                        C348.913513,409.052612 347.336700,405.910278 345.856628,404.441742 
                        C335.108612,393.777435 324.163849,383.310089 313.204285,372.861237 
                        C310.139313,369.939087 306.816376,367.287506 303.147858,364.109955 
                        C303.045776,366.101624 302.979858,367.387634 302.866272,369.505249 
                  M201.048508,273.236023 
                        C197.739670,273.003601 194.431595,272.633331 191.121567,272.615021 
                        C189.993057,272.608765 188.567474,273.199829 187.773224,274.006134 
                        C178.789536,283.126587 169.825745,292.269989 161.050568,301.589844 
                        C159.191055,303.564789 157.411087,306.120514 156.840210,308.685883 
                        C155.957001,312.654785 157.708908,316.021057 161.412827,318.238342 
                        C164.903503,320.327972 166.208557,317.234894 167.956116,315.565796 
                        C176.852524,307.068878 185.632309,298.449982 194.488693,289.910919 
                        C199.588333,284.993958 204.755905,280.147491 210.752716,274.452820 
                        C206.712753,274.013977 204.303131,273.752258 201.048508,273.236023 
                  M213.426392,197.979065 
                        C202.936172,197.989822 192.445419,197.948456 181.956177,198.054916 
                        C179.836044,198.076431 177.613770,198.348801 175.626755,199.043594 
                        C171.352768,200.538071 169.263412,204.926865 170.069687,209.548172 
                        C170.922592,214.436661 174.693161,212.894348 177.560455,212.960281 
                        C194.179520,213.342438 210.801331,213.605927 227.422470,213.895203 
                        C229.816879,213.936874 232.212646,213.900970 235.876968,213.900970 
                        C232.497131,209.873749 229.608246,207.258270 227.760025,204.044342 
                        C224.631271,198.603622 220.144379,197.147385 213.426392,197.979065 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M486.215118,531.394165 
                        C485.713989,521.079895 485.161591,511.149597 485.113312,501.216858 
                        C485.097839,498.037750 486.187927,494.778595 487.182037,491.687927 
                        C488.466797,487.693726 491.968903,486.135803 495.705139,486.082367 
                        C509.522797,485.884521 523.345032,486.003662 537.581970,486.003662 
                        C537.581970,503.293335 537.581970,520.328613 537.581970,537.683655 
                        C533.959595,537.683655 530.372498,537.683655 526.000000,537.683655 
                        C526.000000,535.278442 526.000122,532.855957 526.000000,530.433533 
                        C525.999573,521.101501 525.918518,511.768372 526.043213,502.437988 
                        C526.085510,499.277649 525.049561,497.882843 521.733765,497.956360 
                        C514.904724,498.107788 508.069000,498.071533 501.238129,497.969025 
                        C498.361450,497.925842 496.904938,498.840057 496.965912,501.965851 
                        C497.089478,508.295898 497.088440,514.631653 496.965668,520.961731 
                        C496.906219,524.024475 498.222961,525.075745 501.165680,525.032959 
                        C508.488007,524.926331 515.812927,524.997803 523.940308,524.997803 
                        C523.940308,528.631775 524.118408,532.056335 523.821350,535.439270 
                        C523.742065,536.342224 522.199524,537.850464 521.345276,537.838989 
                        C512.073059,537.715149 502.770355,537.696106 493.556610,536.809937 
                        C491.019592,536.565918 488.751007,533.531311 486.215118,531.394165 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M362.205688,486.861359 
                        C363.890320,486.511414 365.164124,486.040375 366.440125,486.034363 
                        C378.429535,485.977844 390.419769,486.055359 402.408722,485.962555 
                        C405.413452,485.939301 407.067749,486.641327 407.044250,490.104980 
                        C406.937439,505.840393 406.997437,521.576904 406.997437,537.652466 
                        C402.023468,537.652466 398.435791,537.652466 394.205322,537.652466 
                        C394.205322,524.737488 394.205322,511.696167 394.205322,498.032654 
                        C385.318298,498.032654 377.041412,497.804260 368.802460,498.260864 
                        C367.453339,498.335602 365.316406,501.357330 365.185455,503.137787 
                        C364.734619,509.265930 365.043457,515.447876 364.998627,521.608826 
                        C364.976257,524.678772 366.885803,525.030945 369.307251,525.015320 
                        C376.614685,524.968140 383.922638,524.998230 391.613342,524.998230 
                        C391.613342,529.354614 391.613342,533.387512 391.613342,538.413208 
                        C381.254852,537.957031 371.038055,537.737854 360.863922,536.960449 
                        C355.881714,536.579773 354.072266,532.683044 354.031128,528.112488 
                        C353.938263,517.788818 353.825378,507.459534 354.077087,497.141235 
                        C354.198181,492.178009 356.432892,488.234192 362.205688,486.861359 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M441.317993,497.460144 
                        C446.061157,498.247498 450.606323,498.890106 455.158966,498.948303 
                        C467.127991,499.101318 467.126862,498.999786 468.130463,487.418365 
                        C468.156372,487.119690 468.384552,486.838562 468.643951,486.277954 
                        C472.113953,486.277954 475.701080,486.277954 479.953461,486.277954 
                        C478.922852,494.654938 483.680237,504.098694 473.484528,510.437042 
                        C478.412750,512.149780 479.935608,515.940857 479.984955,520.535706 
                        C480.045349,526.158936 479.999359,531.783325 479.999359,537.699219 
                        C475.927795,537.699219 472.342224,537.699219 467.996918,537.699219 
                        C467.996918,533.749512 467.924072,529.821838 468.021851,525.898438 
                        C468.089905,523.168396 466.986176,521.950073 464.196869,521.978088 
                        C456.720428,522.053223 449.242737,522.003723 440.999878,522.003723 
                        C440.999878,525.734436 440.788818,529.174500 441.054382,532.577332 
                        C441.372284,536.650879 439.987976,538.540222 435.715698,538.039062 
                        C433.758667,537.809387 431.752350,537.999878 429.123840,537.999878 
                        C429.123840,531.127502 428.466980,524.442932 429.417419,517.995178 
                        C429.853760,515.035156 433.445343,512.540283 435.532349,509.926880 
                        C424.344940,505.363129 430.643890,494.892578 428.902618,487.160370 
                        C429.644592,486.454102 429.753113,486.255829 429.884003,486.239746 
                        C440.650238,484.917053 440.650360,484.918091 441.317993,497.460144 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M322.019043,498.000000 
                        C309.000000,498.000000 309.000000,498.000000 309.000000,510.648987 
                        C309.000000,515.137695 309.000000,519.626404 309.000000,524.750977 
                        C322.564240,524.750977 335.931549,524.750977 349.644073,524.750977 
                        C349.644073,529.290466 349.644073,533.206970 349.644073,537.775940 
                        C348.058929,537.845886 346.311249,537.986633 344.563293,537.989685 
                        C333.077850,538.009705 321.592346,538.008484 310.106842,537.997192 
                        C301.057404,537.988342 297.022766,534.034546 297.003784,525.149048 
                        C296.986053,516.826233 296.948273,508.502960 297.015625,500.180695 
                        C297.074921,492.853607 300.575073,485.908264 311.032349,485.982025 
                        C322.350830,486.061859 333.671600,486.096222 344.988586,485.943298 
                        C348.508209,485.895782 350.215118,486.795410 350.063202,490.684814 
                        C349.823303,496.827057 349.999390,502.985565 349.999390,509.567261 
                        C345.742798,509.567261 341.709015,509.567261 337.199890,509.567261 
                        C337.199890,506.001495 337.199890,502.411346 337.199890,498.000000 
                        C332.015656,498.000000 327.264618,498.000000 322.019043,498.000000 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M94.475349,537.676025 
                        C91.934067,537.872009 89.797668,537.872009 87.000000,537.872009 
                        C87.000000,529.361267 87.000084,521.091064 86.999962,512.820801 
                        C86.999893,508.657166 87.067055,504.492126 86.981071,500.330261 
                        C86.886009,495.728699 84.782379,494.002136 79.516457,494.000000 
                        C74.215736,493.997833 72.053154,495.700806 72.025818,500.297119 
                        C71.952637,512.602112 72.000877,524.907898 72.000877,537.604614 
                        C69.322807,537.604614 66.959427,537.604614 64.000000,537.604614 
                        C64.000000,527.235901 64.000000,516.959778 64.000000,506.683655 
                        C64.000000,503.352753 63.989079,500.021790 64.002266,496.690918 
                        C64.030731,489.503571 67.358566,485.927063 74.645760,486.080261 
                        C80.204239,486.197144 85.746552,487.428894 91.297813,487.442444 
                        C96.497902,487.455139 101.692253,486.203278 106.903496,486.070068 
                        C115.894135,485.840240 118.991951,489.156342 118.997864,498.290619 
                        C119.006264,511.262817 119.000000,524.234985 119.000000,537.601746 
                        C116.331589,537.601746 113.967262,537.601746 110.999634,537.601746 
                        C110.999634,525.576050 111.036903,513.635376 110.982956,501.695129 
                        C110.958229,496.221741 108.851883,494.128387 103.557617,494.000610 
                        C97.161438,493.846252 96.005287,494.963837 96.001610,501.350739 
                        C95.995651,511.676575 96.050995,522.002869 95.949738,532.327759 
                        C95.932861,534.048462 95.253471,535.762756 94.475349,537.676025 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M166.184723,535.315796 
                        C164.257736,536.240784 162.610687,537.423706 160.960571,537.427979 
                        C151.554520,537.452515 142.137955,537.398621 132.745682,536.943909 
                        C128.488556,536.737793 126.205116,533.381042 126.140213,529.482910 
                        C125.902298,515.194519 126.045952,500.899811 126.045952,486.306458 
                        C128.730209,486.306458 130.987244,486.306458 134.000000,486.306458 
                        C134.000000,488.724792 133.999924,491.153259 134.000000,493.581757 
                        C134.000290,503.580597 134.055862,513.579956 133.972565,523.578064 
                        C133.941254,527.335815 135.517365,529.093872 139.330414,529.022949 
                        C144.494705,528.926758 149.668869,528.836304 154.826157,529.049438 
                        C159.305923,529.234619 161.137833,527.473755 161.058136,522.919250 
                        C160.874481,512.423706 160.990829,501.922668 161.009644,491.423950 
                        C161.012543,489.798401 161.143341,488.173065 161.226685,486.282837 
                        C164.105927,486.282837 166.694458,486.282837 170.000000,486.282837 
                        C170.000000,494.443665 170.122620,502.546692 169.961624,510.644043 
                        C169.830246,517.251282 169.507843,523.864746 168.932861,530.445923 
                        C168.794159,532.033569 167.322113,533.504700 166.184723,535.315796 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M227.171555,486.028809 
                        C229.246658,486.079742 230.865707,486.150940 232.933075,486.241882 
                        C232.933075,490.790466 232.933075,495.053955 232.933075,499.776672 
                        C238.969788,499.776672 244.556061,499.776672 250.569351,499.776672 
                        C250.569351,502.936890 250.569351,505.635895 250.569351,508.813599 
                        C244.996750,508.813599 239.405533,508.813599 233.111267,508.813599 
                        C233.111267,514.497925 232.832657,519.765991 233.218597,524.984863 
                        C233.440552,527.986145 235.674728,529.142578 238.914627,529.074402 
                        C248.706299,528.868103 258.505096,528.999817 268.647827,528.999817 
                        C268.647827,531.965759 268.647827,534.553162 268.647827,538.000000 
                        C267.034119,538.000000 265.293457,538.000000 263.552826,538.000000 
                        C254.727478,538.000000 245.901840,538.046265 237.076889,537.986938 
                        C228.351761,537.928284 225.013763,534.587769 225.003540,526.032043 
                        C224.989426,514.209412 224.938934,502.386047 225.076035,490.565002 
                        C225.093567,489.053528 226.143723,487.554077 227.171555,486.028809 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M175.000000,522.821289 
                        C175.000000,510.403503 175.000000,498.462433 175.000000,486.264648 
                        C178.142242,486.264648 180.728394,486.264648 184.000000,486.264648 
                        C184.000000,494.308044 183.999878,502.239594 184.000061,510.171143 
                        C184.000168,514.667358 184.075790,519.165161 183.978104,523.659241 
                        C183.895447,527.461182 185.642685,529.058228 189.405136,529.027039 
                        C199.368134,528.944519 209.332275,528.999084 219.645401,528.999084 
                        C219.645401,531.960449 219.645401,534.547363 219.645401,538.000000 
                        C215.880356,538.000000 211.972351,538.000000 208.064331,538.000000 
                        C201.902863,538.000000 195.741394,538.003662 189.579926,537.999146 
                        C178.906158,537.991333 175.042038,534.092712 175.000000,522.821289 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M411.000000,526.952271 
                        C411.000000,513.206421 411.000000,499.952209 411.000000,486.350739 
                        C415.294647,486.350739 419.203674,486.350739 423.575653,486.350739 
                        C423.702698,487.419006 423.977295,488.665924 423.979950,489.913422 
                        C424.010498,504.192841 423.777435,518.477600 424.113831,532.749146 
                        C424.240936,538.141602 421.407654,538.140381 417.584534,538.047424 
                        C413.987213,537.959961 410.235016,538.544922 410.963806,532.918457 
                        C411.196472,531.122192 411.000061,529.270264 411.000000,526.952271 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M283.000000,499.010193 
                        C283.000000,512.125183 283.000000,524.741516 283.000000,537.675598 
                        C280.304993,537.675598 278.049377,537.675598 275.394958,537.675598 
                        C275.394958,520.708862 275.394958,503.679291 275.394958,486.324158 
                        C277.686615,486.324158 279.942078,486.324158 283.000000,486.324158 
                        C283.000000,490.337219 283.000000,494.424377 283.000000,499.010193 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M420.672363,481.987091 
                        C419.888672,481.997589 419.558319,481.999329 419.227966,481.999481 
                        C410.366669,482.003815 410.366669,482.003784 411.366028,472.367523 
                        C415.338440,472.367523 419.354889,472.367523 423.858490,472.367523 
                        C422.977112,475.719757 426.560730,479.916321 420.672363,481.987091 
                        z"
                  />
                  <path fill="#34607D" opacity="1.000000" stroke="none" d="M278.093750,481.995392 
                        C276.758423,480.949432 275.382111,480.018494 275.118042,478.838654 
                        C274.693604,476.942169 275.004181,474.881165 275.004181,472.445801 
                        C277.569427,472.445801 279.936493,472.445801 282.658142,472.445801 
                        C282.658142,475.296082 282.658142,478.218231 282.658142,481.661499 
                        C281.386444,481.765930 279.969208,481.882324 278.093750,481.995392 
                        z"
                  />
                  <path fill="#35607D" opacity="1.000000" stroke="none" d="M263.035034,240.027832 
                        C278.985809,225.345566 296.915619,221.403687 317.354980,227.809113 
                        C344.535980,236.327301 359.125183,270.489960 346.152344,295.832794 
                        C328.165863,330.969971 281.956360,335.030396 258.053009,303.574066 
                        C245.108719,286.539642 245.920731,259.972870 259.883087,243.716110 
                        C260.860016,242.578629 261.840576,241.444214 263.035034,240.027832 
                        z"
                  />
                  <path fill="#DA3B41" opacity="1.000000" stroke="none" d="M246.738571,232.694183 
                        C242.867264,238.853760 239.339859,244.813904 235.571045,250.617294 
                        C234.661758,252.017456 232.995148,253.812805 231.606354,253.875900 
                        C219.166107,254.441101 206.711563,254.806442 194.258209,254.931870 
                        C184.790268,255.027222 178.780609,249.672699 176.964920,240.308731 
                        C175.802078,234.311691 174.445740,228.352219 173.190674,222.372849 
                        C173.162628,222.239227 173.309128,222.068970 173.636398,221.298813 
                        C175.089127,221.231659 176.838562,221.081482 178.588043,221.080612 
                        C197.227783,221.071442 215.878357,221.453629 234.501709,220.905258 
                        C241.532639,220.698227 246.107407,223.317856 250.186234,228.883041 
                        C249.163300,230.021896 248.078568,231.229538 246.738571,232.694183 
                        z"
                  />
                  <path fill="#DA3A41" opacity="1.000000" stroke="none" d="M415.339844,213.672989 
                        C419.665405,216.143448 423.667664,218.436356 428.081665,220.965195 
                        C425.070770,224.473251 422.498444,227.640701 419.747986,230.645081 
                        C408.318390,243.129700 396.561005,255.328705 385.511017,268.138947 
                        C381.295074,273.026520 376.317841,273.178436 370.403809,273.608887 
                        C370.142944,270.220184 370.371979,266.703583 369.512817,263.476715 
                        C368.029022,257.903625 365.741638,252.548843 364.079193,247.016006 
                        C363.641541,245.559494 363.630768,243.248596 364.491028,242.241669 
                        C372.245636,233.164963 380.070831,224.135681 388.206879,215.401337 
                        C396.578949,206.413635 402.692657,205.898987 413.268585,212.538132 
                        C413.830200,212.890701 414.432953,213.177841 415.339844,213.672989 
                        z"
                  />
                  <path fill="#DA3A41" opacity="1.000000" stroke="none" d="M271.705139,213.680542 
                        C267.583710,211.036255 263.571869,208.508881 260.083313,205.395233 
                        C253.511063,199.529282 247.157181,193.410248 240.867355,187.238632 
                        C234.305420,180.800034 233.353409,174.620453 237.787582,166.488235 
                        C241.033997,160.534332 244.688995,154.803223 248.489899,148.422104 
                        C254.151520,153.619354 259.454712,158.309662 264.557587,163.208618 
                        C275.479523,173.694031 286.563202,184.036636 296.951202,195.032394 
                        C299.229675,197.444138 299.017365,202.318619 299.546204,206.115494 
                        C299.603363,206.525970 296.930664,207.872238 295.548737,207.836563 
                        C287.171204,207.620316 279.499847,210.059372 271.705139,213.680542 
                        z"
                  />
                  <path fill="#DA3A41" opacity="1.000000" stroke="none" d="M302.128235,344.043091 
                        C303.386749,343.085022 304.570679,342.371918 305.853210,342.042084 
                        C313.187439,340.155823 320.672791,338.743286 327.834320,336.353271 
                        C330.855042,335.345123 332.730286,335.458893 334.917114,337.454895 
                        C344.005951,345.750763 353.203613,353.928223 362.240875,362.279480 
                        C367.489197,367.129425 368.824524,374.689240 365.490936,381.026215 
                        C362.108551,387.455994 358.374512,393.700775 354.549072,400.460236 
                        C349.729462,396.167877 345.356934,392.404449 341.130371,388.483704 
                        C330.641510,378.753754 320.366302,368.787445 309.701538,359.255951 
                        C305.119965,355.161285 302.010590,350.749054 302.128235,344.043091 
                        z"
                  />
                  <path fill="#DA3A41" opacity="1.000000" stroke="none" d="M191.740417,303.740479 
                        C200.223389,295.244751 208.454529,286.997162 216.690079,278.753967 
                        C222.107025,273.332001 222.266159,273.286774 229.196701,274.390472 
                        C231.832016,283.854706 234.415314,292.831116 236.745911,301.872711 
                        C237.046661,303.039398 236.437485,304.919952 235.575394,305.833221 
                        C226.558517,315.385223 217.440384,324.842804 208.270096,334.248322 
                        C204.410034,338.207397 198.954300,339.579437 194.431442,337.660767 
                        C186.484192,334.289429 179.191284,329.375641 170.710144,324.592255 
                        C178.312134,317.055542 184.901291,310.523010 191.740417,303.740479 
                        z"
                  />
                  <path fill="#DA3A41" opacity="1.000000" stroke="none" d="M328.023743,157.990204 
                        C336.532745,152.467163 346.135681,152.290573 355.955231,149.397568 
                        C355.955231,152.188400 355.951477,153.967590 355.955841,155.746750 
                        C355.993195,170.907440 355.995697,186.068405 356.101471,201.228622 
                        C356.122223,204.202850 356.333954,207.209244 356.822296,210.140030 
                        C358.012299,217.282242 355.934875,222.853012 350.652893,225.831345 
                        C342.204163,221.114700 334.274872,216.755142 326.438660,212.234283 
                        C325.377899,211.622314 324.114075,210.225006 324.075592,209.150818 
                        C323.581543,195.359711 322.987549,181.556961 323.180420,167.767792 
                        C323.225403,164.552353 326.120697,161.376770 328.023743,157.990204 
                        z"
                  />
                  <path fill="#DA3A41" opacity="1.000000" stroke="none" d="M384.000000,328.021118 
                        C376.012665,328.037781 368.523651,328.143677 361.039337,328.001678 
                        C359.347473,327.969574 357.395325,327.467682 356.036346,326.510193 
                        C349.828979,322.136658 350.298462,322.362793 354.961121,316.254028 
                        C358.737396,311.306610 361.608490,305.606964 364.409851,300.005310 
                        C365.622620,297.580261 366.371033,295.872803 369.575592,295.838409 
                        C383.023285,295.694061 396.469635,294.983002 409.912933,295.063507 
                        C416.414185,295.102448 420.720398,299.458160 422.579590,305.424194 
                        C424.782501,312.493256 426.229370,319.797943 428.255249,328.020599 
                        C413.250854,328.020599 398.875427,328.020599 384.000000,328.021118 
                        z"
                  />
                  <path fill="#DA3A41" opacity="1.000000" stroke="none" d="M269.534180,392.760956 
                        C261.143127,395.171722 253.074539,397.392822 243.926132,399.911133 
                        C243.926132,396.917358 243.924179,395.176422 243.926422,393.435486 
                        C243.950836,374.476135 244.317398,355.507721 243.813492,336.562439 
                        C243.639816,330.032745 246.550781,326.090546 250.323624,322.547363 
                        C257.036865,326.973480 263.060303,332.182465 270.035767,335.164276 
                        C275.946503,337.690979 277.331818,340.842407 277.092499,346.644012 
                        C276.681610,356.605042 276.702148,366.609192 277.124603,376.570496 
                        C277.412994,383.369690 275.126007,388.529541 269.534180,392.760956 
                        z"
                  />
                  <path fill="#DB3C41" opacity="1.000000" stroke="none" d="M379.968811,188.960022 
                        C381.112457,196.357880 379.008392,201.539993 372.638641,205.147461 
                        C369.850189,206.726654 367.777588,209.569839 364.567078,212.608414 
                        C364.567078,190.413406 364.567078,169.258957 364.567078,147.878174 
                        C371.179291,142.867569 379.211121,149.177597 379.126526,157.061874 
                        C379.014313,167.526382 379.648804,177.998901 379.968811,188.960022 
                        z"
                  />
                  <path fill="#DB3C41" opacity="1.000000" stroke="none" d="M220.064056,392.432770 
                        C220.049423,379.513336 220.146713,367.033661 219.978027,354.557526 
                        C219.931778,351.136993 220.558792,348.696899 223.656433,346.610596 
                        C227.703781,343.884644 231.260925,340.430878 235.955505,336.517944 
                        C235.955505,345.656677 236.067032,353.549957 235.928879,361.438873 
                        C235.731247,372.724274 235.452408,384.010925 234.961365,395.286255 
                        C234.865692,397.483032 234.159058,401.047485 232.839203,401.529297 
                        C229.208725,402.854553 225.104202,402.451508 222.557327,398.781860 
                        C221.365967,397.065247 220.880905,394.858459 220.064056,392.432770 
                        z"
                  />
                  <path fill="#DB3B41" opacity="1.000000" stroke="none" d="M399.000000,336.017944 
                        C407.828766,336.017883 416.157501,336.011383 424.486267,336.020050 
                        C430.494263,336.026306 432.750153,339.296661 430.463715,344.936737 
                        C428.969757,348.622009 426.175629,350.869049 422.072876,350.981598 
                        C408.611420,351.350861 395.148834,351.699860 381.684387,351.908661 
                        C380.176575,351.932037 378.147217,351.466278 377.226685,350.445496 
                        C373.368927,346.167694 369.841370,341.592102 365.288086,336.018005 
                        C377.086792,336.018005 387.793396,336.018005 399.000000,336.017944 
                        z"
                  />
                  <path fill="#DB3B41" opacity="1.000000" stroke="none" d="M270.219635,136.664490 
                        C280.032959,145.882904 289.627289,154.803818 299.079956,163.872360 
                        C300.127106,164.876953 300.938141,166.748337 300.877869,168.176331 
                        C300.649323,173.592651 300.017273,178.991943 299.442383,185.450974 
                        C295.815704,182.329376 292.507385,179.787674 289.553955,176.884872 
                        C278.772308,166.288132 268.096283,155.583694 257.413177,144.887161 
                        C255.520874,142.992493 252.474609,141.358261 255.068832,137.932312 
                        C257.504822,134.715347 260.515625,132.401871 264.781555,133.544617 
                        C266.630585,134.039948 268.240540,135.427795 270.219635,136.664490 
                        z"
                  />
                  <path fill="#DA3B41" opacity="1.000000" stroke="none" d="M419.611816,265.591614 
                        C417.915253,267.442474 416.274902,268.890594 414.961945,270.591492 
                        C412.517120,273.758789 409.627106,274.280853 405.749756,273.556396 
                        C401.570007,272.775421 397.201904,273.002625 392.213867,272.755157 
                        C393.811768,270.653931 395.016754,268.745575 396.532196,267.128052 
                        C408.221466,254.651398 419.920959,242.183151 431.760742,229.850006 
                        C433.351074,228.193375 434.686462,224.432526 438.035767,226.817749 
                        C441.410706,229.221222 445.083588,232.230774 442.997925,237.036087 
                        C441.457275,240.585663 439.016968,243.863632 436.478546,246.836624 
                        C431.096985,253.139496 425.389557,259.164154 419.611816,265.591614 
                        z"
                  />
                  <path fill="#DA3B41" opacity="1.000000" stroke="none" d="M302.890106,369.089447 
                        C302.979858,367.387634 303.045776,366.101624 303.147858,364.109955 
                        C306.816376,367.287506 310.139313,369.939087 313.204285,372.861237 
                        C324.163849,383.310089 335.108612,393.777435 345.856628,404.441742 
                        C347.336700,405.910278 348.913513,409.052612 348.296204,410.509003 
                        C346.837830,413.949738 343.542572,416.286682 339.489288,415.540466 
                        C337.160583,415.111755 334.680603,413.883301 332.913116,412.296906 
                        C323.141327,403.526428 313.540497,394.564178 303.968323,385.574738 
                        C303.004120,384.669220 302.066528,383.137421 302.075226,381.903076 
                        C302.104431,377.768799 302.568634,373.637604 302.890106,369.089447 
                        z"
                  />
                  <path fill="#DA3B41" opacity="1.000000" stroke="none" d="M201.471008,273.363281 
                        C204.303131,273.752258 206.712753,274.013977 210.752716,274.452820 
                        C204.755905,280.147491 199.588333,284.993958 194.488693,289.910919 
                        C185.632309,298.449982 176.852524,307.068878 167.956116,315.565796 
                        C166.208557,317.234894 164.903503,320.327972 161.412827,318.238342 
                        C157.708908,316.021057 155.957001,312.654785 156.840210,308.685883 
                        C157.411087,306.120514 159.191055,303.564789 161.050568,301.589844 
                        C169.825745,292.269989 178.789536,283.126587 187.773224,274.006134 
                        C188.567474,273.199829 189.993057,272.608765 191.121567,272.615021 
                        C194.431595,272.633331 197.739670,273.003601 201.471008,273.363281 
                        z"
                  />
                  <path fill="#DA3A41" opacity="1.000000" stroke="none" d="M213.911926,197.978989 
                        C220.144379,197.147385 224.631271,198.603622 227.760025,204.044342 
                        C229.608246,207.258270 232.497131,209.873749 235.876968,213.900970 
                        C232.212646,213.900970 229.816879,213.936874 227.422470,213.895203 
                        C210.801331,213.605927 194.179520,213.342438 177.560455,212.960281 
                        C174.693161,212.894348 170.922592,214.436661 170.069687,209.548172 
                        C169.263412,204.926865 171.352768,200.538071 175.626755,199.043594 
                        C177.613770,198.348801 179.836044,198.076431 181.956177,198.054916 
                        C192.445419,197.948456 202.936172,197.989822 213.911926,197.978989 
                        z"
                  />            
		</SvgIcon>
	);
}

export default MulticaixaIcon;