import React from "react";
import Box from "@material-ui/core/Box";

import PaybyrdLogo from "common/ui/PaybyrdLogo";

import { useStyles } from "./style";

export const Loading = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <PaybyrdLogo open={false} />
    </Box>
  );
};

export default Loading;
