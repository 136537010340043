import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function DevelopersIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M1.2 19.733c-0.267 0-0.667-0.133-0.8-0.4-0.533-0.533-0.533-1.2 0-1.733l7.2-7.2-7.2-7.067c-0.533-0.533-0.533-1.2 0-1.733s1.2-0.533 1.733 0l8 8c0.533 0.533 0.533 1.2 0 1.733l-8 8c-0.267 0.267-0.667 0.4-0.933 0.4zM22.533 22.4h-10.667c-0.667 0-1.2-0.533-1.2-1.2s0.533-1.2 1.2-1.2h10.667c0.667 0 1.2 0.533 1.2 1.2s-0.533 1.2-1.2 1.2z"></path>
		</SvgIcon>
	);
}

export default DevelopersIcon;