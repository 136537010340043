import React, { useState, forwardRef } from "react";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import FormInput from "common/ui/FormInput";
import { useStyles } from "./styles";

export const FormPasswordInput = (
  {
    value = null,
    error,
    label,
    onChange,
    name = "",
    inputComponent = null,
    labelAction = null,
    multiline,
    autoFocus,
    max,
    tabIndex,
    ...props
  },
  ref
) => {
  const classes = useStyles();
  const [showText, setShowText] = useState(false);
  const handleClickShowPassword = () => setShowText(!showText);
  
  return (
    <FormInput label={label} labelAction={labelAction} {...props}>
      <TextField
        ref={ref}
        placeholder={props.placeholder || ""}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        type={showText ? "text" : "password"}
        error={!!error}
        fullWidth
        multiline={multiline}
        rows={multiline && 5}
        InputProps={{
                endAdornment:(
                  <InputAdornment position="end">
                    <IconButton
                      data-testid="showhide"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      tabIndex="-1"
                    >
                      {showText ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
                classes: {
                  input: classes.input,
                  error: classes.error,
                },
        }}
        value={value || ""}
        onChange={(e) => {
          if (max && e.target.value.length > max) {
            return;
          }

          return onChange(e);
        }}
      />
      {!!error && <div className={classes.errorField}>{error}</div>}
    </FormInput>
  );
};

export default forwardRef(FormPasswordInput);
