import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function ShiftsIcon(props) {
	return (
		<SvgIcon {...props}>
			<g>
				<rect width="24" height="24" fill="none"/>
				<path d="M23.03223,13.98828a.85492.85492,0,0,0-.033-.16315l-.01782-3.92572a.901.901,0,0,0-.9004-.896H1.86816a.90107.90107,0,0,0-.90039.90381l.05078,11.1914a.90109.90109,0,0,0,.9004.89649H22.13184a.90192.90192,0,0,0,.90039-.9043l-.0315-6.94653A.85177.85177,0,0,0,23.03223,13.98828Zm-1.84668-3.18506.01019,2.28467H2.78265l-.01019-2.28467ZM2.81445,20.19434l-.02374-5.30567H21.2038l.02374,5.30567Zm16.74274-2.1485A1.05719,1.05719,0,1,1,18.5,16.98871,1.0572,1.0572,0,0,1,19.55719,18.04584Zm-3.55719,0a1.05719,1.05719,0,1,1-1.05719-1.05713A1.0572,1.0572,0,0,1,16,18.04584ZM2.09961,4.98877A.90023.90023,0,0,1,3,4.08887H17.86932l-.548-.54785a.90012.90012,0,0,1,1.27344-1.27247l2.084,2.084a.89944.89944,0,0,1,0,1.27246l-2.084,2.084A.90028.90028,0,0,1,17.32129,6.436l.54773-.54737H3A.90023.90023,0,0,1,2.09961,4.98877Z" fill="#83858f"/>
			</g>
		</SvgIcon>
	);
}

export default ShiftsIcon;