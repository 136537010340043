import { useState } from 'react';
import { useDispatch } from "react-redux";

import { exportReport } from 'store/reducers/export';

export function useExport() {
	const [exporting, setExporting] = useState(false);
	const dispatch = useDispatch();

	const downloadReport = async (filters, type) => {
		setExporting(true);
		await dispatch(exportReport({ filters, type }))
		setExporting(false);
	};

	return { downloadReport, exporting };
}