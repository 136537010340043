import React, { Fragment, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { remove } from 'store/reducers/notifications';

import { CopyToClipboard } from 'react-copy-to-clipboard';

let displayed = [];

const Notifier = () => {
	const dispatch = useDispatch();
	const notifications = useSelector(store => store.notifications.notifications || []);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const storeDisplayed = (id) => {
		displayed = [...displayed, id];
	};

	const removeDisplayed = (id) => {
		displayed = [...displayed.filter(key => id !== key)];
	};

	useEffect(() => {
		notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
			if (dismissed) {
				// dismiss snackbar using notistack
				closeSnackbar(key);
				return;
			}

			// do nothing if snackbar is already displayed
			if (displayed.includes(key)) return;

			// display snackbar using notistack
			enqueueSnackbar(message, {
				key,
				...options,
				variant: options.variant || 'success',
				action: key => (
					<Fragment>
						{options.actions && options.actions.copy && (
							<CopyToClipboard text={options.actions.copy.text} onCopy={() => alert("Link copied to clipboard!")}>
								<Button>copy</Button>
							</CopyToClipboard>
						)}
						<Button onClick={() => closeSnackbar(key)}>dismiss</Button>
					</Fragment>
				),
				onClose: (event, reason, myKey) => {
					if (options.onClose) {
						options.onClose(event, reason, myKey);
					}
				},
				onExited: (event, myKey) => {
					// remove this snackbar from redux store
					dispatch(remove(myKey));
					removeDisplayed(myKey);
				},
			});

			// keep track of snackbars that we've displayed
			storeDisplayed(key);
		});
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

	return null;
};

export default Notifier;